import { Features, hasFeature } from '@ardoq/features';
import {
  awsUrl,
  azureUrl,
  confluenceUrl,
  excel2Url,
  microsoftEntraId,
  powerBiUrl,
  publicApiUrl,
  servicenow2Url,
  sharepointUrl,
  signavioExporterUrl,
  lucidUrl,
  webhookUrl,
} from './common/logos';
import MicrosoftEntraId from './microsoftEntraId/init';
import Aws from './aws/init';
import Azure from './azure/init';
import Excel from './excel-v3/init';
import ServiceNow from './service-now/init';
import PublicApi from './publicApi/init';
import { ITPedia } from './ITPedia/ITPedia';
import { Category, Group, Integration } from './types';
import { unifiedIntegrations } from './unified/tileConfigs';
import { Signavio } from 'integrations/signavio-exporter/init';
import { OverviewRoute } from 'integrations/common/navigation/types';
import {
  awsDictionary,
  azureDictionary,
  excelDictionary,
  microsoftEntraIdDictionary,
  serviceNowDictionary,
} from './common/dictionary';
import { IconName } from '@ardoq/icons';
import { APICurrentUser, OrgAccessLevel } from '@ardoq/api-types';
import { permissionsOperations } from '@ardoq/access-control';
import { capitalize } from 'lodash';
import { Lucidchart } from './lucidchart/import/init';

const defaultOverviewRoutes: OverviewRoute[] = [
  OverviewRoute.SCHEDULES,
  OverviewRoute.CONFIGURATIONS,
  OverviewRoute.CONNECTIONS,
];

const microsoftEntraIdTSImporter: Integration = {
  id: 'microsoft-entra-id',
  name: microsoftEntraIdDictionary.name,
  categories: [Category.SERVICE, Category.IMPORTER],
  description: `Import from ${microsoftEntraIdDictionary.name}`,
  logoUrl: microsoftEntraId,
  // Using the same feature toggle as ad-people integration
  requiredFeatures: [Features.AD_ENABLE],
  importerPath: 'microsoft-entra-id',
  additionalEntryPath: ['ad-people'],
  typescriptImporter: {
    component: MicrosoftEntraId,
    config: {
      allowedOverviewRoutes: defaultOverviewRoutes,
    },
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

const awsTSImporter: Integration = {
  id: 'aws-v3',
  name: awsDictionary.name,
  categories: [Category.SERVICE, Category.IMPORTER],
  description: 'Import from Aws Cloud',
  logoUrl: awsUrl,
  requiredFeatures: [Features.AWS_ENABLE],
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  importerPath: 'aws-v3',
  additionalEntryPath: ['aws'],
  typescriptImporter: {
    component: Aws,
    config: {
      allowedOverviewRoutes: defaultOverviewRoutes,
    },
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

const azureTSImporter: Integration = {
  id: 'azure-v3',
  name: azureDictionary.name,
  categories: [Category.SERVICE, Category.IMPORTER],
  description: `Import from ${azureDictionary.name} Cloud`,
  logoUrl: azureUrl,
  requiredFeatures: [Features.AZURE_ENABLE],
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  importerPath: 'azure-v3',
  additionalEntryPath: ['azure'],
  typescriptImporter: {
    component: Azure,
    config: {
      allowedOverviewRoutes: defaultOverviewRoutes,
    },
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

const confluenceIntegration = {
  id: 'confluence',
  name: 'Confluence',
  categories: [Category.SERVICE, Category.DOCUMENTATION],
  description: 'Integrate with Confluence',
  logoUrl: confluenceUrl,
  requiredFeatures: [Features.CONFLUENCE_ENABLE],
  isEnabledWhenFeaturesUnset: true,
  helpUrl:
    'https://help.ardoq.com/en/articles/44062-embedding-presentations-into-confluence-using-iframes',
  group: Group.GUIDES,
  requiredOrgAccessLevel: OrgAccessLevel.READER,
};

const excelTSImporter: Integration = {
  id: 'excel-v3',
  name: excelDictionary.name,
  categories: [Category.FILE, Category.IMPORTER],
  description: 'Import excel document',
  logoUrl: excel2Url,
  requiredFeatures: [Features.EXCEL_ENABLE],
  importerPath: 'excel-v3',
  additionalEntryPath: ['excel'],
  typescriptImporter: {
    component: Excel,
    config: {
      allowedOverviewRoutes: [OverviewRoute.CONFIGURATIONS],
    },
  },
  requiredOrgAccessLevel: OrgAccessLevel.WRITER,
  group: Group.INTEGRATIONS,
};

const itPediaImporter: Integration = {
  id: 'it-pedia',
  name: 'IT-Pedia',
  categories: [Category.SERVICE, Category.IMPORTER],
  description: 'Import IT Pedia components',
  // We can't use logo folder for now since png imorts are not supported by jest
  logoUrl: '/img/it-pedia/logo.png',
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  importerPath: 'it-pedia',
  typescriptImporter: {
    component: ITPedia,
    config: {
      allowedOverviewRoutes: [
        OverviewRoute.SCHEDULES,
        OverviewRoute.CONNECTIONS,
      ],
    },
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

const signavioExporter: Integration = {
  id: 'signavio-exporter',
  name: 'SAP Signavio Exporter',
  categories: [Category.SERVICE, Category.EXPORTER],
  description: 'Export components to SAP Signavio',
  logoUrl: signavioExporterUrl,
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  importerPath: 'signavio-exporter',
  typescriptImporter: {
    component: Signavio,
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

const powerBiIntegration = {
  id: 'powerbi',
  name: 'Power BI',
  categories: [Category.SERVICE, Category.DOCUMENTATION],
  description: 'Power BI',
  logoUrl: powerBiUrl,
  requiredFeatures: [Features.POWERBI_ENABLE],
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  helpUrl: 'https://help.ardoq.com/en/articles/44079-power-bi-integration',
  group: Group.GUIDES,
  requiredOrgAccessLevel: OrgAccessLevel.READER,
};

export const publicApiIntegration = {
  id: 'public-api',
  name: 'Ardoq API',
  categories: [Category.API, Category.DOCUMENTATION],
  description: 'Import data using the rest api',
  logoUrl: publicApiUrl,
  requiredFeatures: [Features.RESTAPI_ENABLE],
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  typescriptImporter: {
    component: PublicApi,
  },
  importerPath: 'public-api',
  group: Group.GUIDES,
  requiredOrgAccessLevel: OrgAccessLevel.READER,
};

const servicenowTSImporter: Integration = {
  id: 'servicenow-v3',
  name: serviceNowDictionary.name,
  categories: [Category.SERVICE, Category.IMPORTER],
  description: `Import from ${serviceNowDictionary.name}`,
  logoUrl: servicenow2Url,
  requiredFeatures: [Features.SERVICENOW_ENABLE],
  importerPath: 'servicenow-v3',
  additionalEntryPath: ['servicenow-next'],
  typescriptImporter: {
    component: ServiceNow,
    config: {
      allowedOverviewRoutes: defaultOverviewRoutes,
    },
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

const sharepointIntegration = {
  id: 'sharepoint',
  name: 'Sharepoint',
  categories: [Category.SERVICE, Category.DOCUMENTATION],
  description: 'Integrate with Sharepoint',
  logoUrl: sharepointUrl,
  requiredFeatures: [Features.SHAREPOINT_ENABLE],
  isEnabledWhenFeaturesUnset: true,
  helpUrl:
    'https://help.ardoq.com/en/articles/44063-embedding-presentations-into-microsoft-sharepoint-using-iframes',
  group: Group.GUIDES,
  requiredOrgAccessLevel: OrgAccessLevel.READER,
};

const webhooksIntegration = {
  id: 'webhooks',
  name: 'Webhooks',
  categories: [Category.SERVICE, Category.DOCUMENTATION],
  description: 'Connect using webhooks',
  logoUrl: webhookUrl,
  requiredFeatures: [Features.WEBHOOKS_ENABLE],
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  helpUrl:
    'https://help.ardoq.com/en/articles/44099-setting-up-webhooks-in-ardoq',
  group: Group.GUIDES,
  requiredOrgAccessLevel: OrgAccessLevel.READER,
};

const lucidGuide = {
  id: 'lucid-guide',
  name: 'Lucidchart',
  categories: [Category.SERVICE, Category.DOCUMENTATION],
  description: 'Connect using Lucidchart',
  logoUrl: lucidUrl,
  requiredFeatures: [Features.LUCID_CHART_ENABLE],
  disabledFeatures: [Features.QUICK_START_RESTRICT_AVAILABLE_INTEGRATIONS],
  helpUrl: 'https://help.ardoq.com/en/articles/263887-lucidchart-integration',
  group: Group.GUIDES,
  requiredOrgAccessLevel: OrgAccessLevel.READER,
};

const lucid = {
  id: 'lucid',
  name: 'Lucidchart',
  categories: [Category.SERVICE, Category.IMPORTER],
  description: `Import from Lucidchart`,
  logoUrl: lucidUrl,
  requiredFeatures: [Features.LUCID_IMPORT_ENABLE],
  importerPath: 'lucid',
  typescriptImporter: {
    component: Lucidchart,
  },
  group: Group.INTEGRATIONS,
  requiredOrgAccessLevel: OrgAccessLevel.ADMIN,
};

export const allIntegrations: Integration[] = [
  microsoftEntraIdTSImporter,
  awsTSImporter,
  azureTSImporter,
  confluenceIntegration,
  powerBiIntegration,
  publicApiIntegration,
  sharepointIntegration,
  webhooksIntegration,
  itPediaImporter,
  excelTSImporter,
  servicenowTSImporter,
  signavioExporter,
  lucidGuide,
  lucid,
  ...unifiedIntegrations,
];

export const getIntegrationDisabledOptions = (
  {
    disabledFeatures,
    requiredFeatures,
    requiredOrgAccessLevel,
    disableOptions,
  }: Integration,
  currentUser: APICurrentUser
): Integration['disableOptions'] => {
  const genericNotAvailableReason = {
    hint: 'Integration not available',
    icon: IconName.LOCK,
  };

  // Integration is explicitly disabled
  if (disableOptions?.isDisabled) {
    return {
      isDisabled: true,
      reason: disableOptions.reason || genericNotAvailableReason,
    };
  }

  // Integration is disabled by feature
  if (disabledFeatures && disabledFeatures.some(hasFeature)) {
    return {
      isDisabled: true,
      reason: genericNotAvailableReason,
    };
  }

  // Integration is required by feature
  if (requiredFeatures && !requiredFeatures.every(hasFeature)) {
    return {
      isDisabled: true,
      reason: genericNotAvailableReason,
    };
  }

  // Integration require specific org access level
  if (
    !permissionsOperations.hasRequiredOrgAccessLevel(
      requiredOrgAccessLevel,
      currentUser
    )
  )
    return {
      isDisabled: true,
      reason: {
        hint: `${capitalize(requiredOrgAccessLevel)} access required to perform the action`,
        icon: IconName.SSO,
      },
    };

  return { isDisabled: false };
};

export const getIntegrationByImporterPath = (integrationBasePath: string) => {
  return allIntegrations.find(
    ({ additionalEntryPath = [], importerPath }) =>
      importerPath === integrationBasePath ||
      additionalEntryPath.includes(integrationBasePath)
  );
};

export const getIntegrationById = (integrationId: string) => {
  return allIntegrations.find(({ id }) => id === integrationId);
};
