import { workspaceApi, handleError } from '@ardoq/api';
import {
  action$,
  ofType,
  extractPayload,
  streamReducer,
  reducer,
} from '@ardoq/rxbeach';
import {
  filter,
  merge,
  shareReplay,
  switchMap,
  map,
  from,
  withLatestFrom,
  Observable,
} from 'rxjs';
import { requestLoadWorkspaces } from '../createScopeDataRequest';
import { isArdoqError } from '@ardoq/common-helpers';
import workspaces$ from 'streams/workspaces/workspaces$';
import { reducedStream } from '@ardoq/rxbeach';
import { scopeDataOperations } from '@ardoq/scope-data';
import { APIScopeData } from '@ardoq/api-types';
import { closeAllOpenData } from 'componentHierarchies/actions';

const loadScopeData$ = action$.pipe(
  ofType(requestLoadWorkspaces),
  extractPayload(),
  map(({ workspaceIds }) => workspaceIds),
  switchMap(workspaceApi.loadWorkspaceHierarchies),
  shareReplay({ bufferSize: 1, refCount: true })
);

const loadScopeDataNoErrors$ = loadScopeData$.pipe(
  handleError(),
  map(scopeData => ({ ...scopeData, scopeComponents: [] }))
);

const mergeScopeData = (
  state: APIScopeData,
  update: APIScopeData
): APIScopeData => scopeDataOperations.merge(state, update);

const clearScopeData = (): APIScopeData => scopeDataOperations.getEmpty();

export const scopeData$: Observable<APIScopeData> = reducedStream(
  'scopeData$',
  scopeDataOperations.getEmpty(),
  [
    streamReducer(loadScopeDataNoErrors$, mergeScopeData),
    reducer(closeAllOpenData, clearScopeData),
  ]
);

export const loadingScopeDataNotification$ = merge(
  action$.pipe(
    ofType(requestLoadWorkspaces),
    extractPayload(),
    withLatestFrom(workspaces$),
    map(([{ workspaceIds }, { byId }]) => {
      const label = workspaceIds
        .map(workspaceId => byId[workspaceId]?.name)
        .filter(Boolean)
        .join(', ');
      return `Loading workspace ${label}...`;
    })
  ),
  loadScopeData$.pipe(map(() => null)),
  from([null])
);

export const loadScopeDataError$ = merge(
  loadScopeData$.pipe(
    filter(data => isArdoqError(data)),
    map(() => "Sorry, we couldn't load the workspace")
  ),
  action$.pipe(
    ofType(requestLoadWorkspaces),
    map(() => null)
  ),
  from([null])
);
