import {
  carry,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  exportToLucidchart,
  linkDocument,
  openDocumentPicker,
  openEmbedViewer,
} from './actions';
import {
  catchError,
  filter,
  from,
  map,
  of,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { api, handleError, integrationsLucidChartApi } from '@ardoq/api';
import {
  showDocumentPickerDrawer,
  showEmbedViewerDrawer,
} from './DocumentPickerDrawer';
import { LucidChartDocumentPickerStatus } from './types';
import {
  closeDocumentPicker,
  createLucidDocumentPickerStream,
} from './streams';
import {
  authRequiredLucidchartAction,
  createDocumentPickerUrl,
  getExportLucidDocumentTitle,
  getJSONGraph,
  getLucidEditUrl,
  isSuccessDocumentSelection,
} from './utils';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { showToast, ToastType } from '@ardoq/status-ui';
import viewModifiers$ from 'appLayout/ardoqStudio/viewPaneHeader/viewModifiers$';
import { ANALYTIC_EVENTS, LUCIDCHART_INVALID_SESSION } from './const';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import {
  ExternalDocumentTargetType,
  isTraversalLoadedState,
  SlideTypes,
  ViewIds,
} from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';
import { loadedState$ } from 'loadedState/loadedState$';
import { traversalState$ } from 'streams/traversals$';
import {
  closeCreatingDocumentModal,
  completeCreatingDocument,
  showExportErrorAlert,
  showUnexpectedErrorDialog,
  startCreatingDocumentModal,
} from './CreatingDocumentModal';
import { addSlideToPresentationDialog } from 'presentationEditor/presentationChooser/actions';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';

const handleArdoqError = (error: unknown) => {
  if (isArdoqError(error)) {
    return of(error);
  }
  return throwError(() => error);
};

const handleOpenDocumentPicker = routine(
  ofType(openDocumentPicker),
  extractPayload(),
  carry(
    switchMap(payload =>
      authRequiredLucidchartAction({
        lucidchartAction: integrationsLucidChartApi.pickerToken,
        onCompleteAction: openDocumentPicker,
        payload,
        operation: 'open Lucidchart document picker',
      })
    )
  ),
  tap(([{ componentId }, response]) => {
    if (isArdoqError(response)) {
      return;
    }

    const { token } = response;
    trackEvent(ANALYTIC_EVENTS.OPENED_DOCUMENT_PICKER);

    const titleSuggestion =
      componentInterface.getDisplayName(componentId) ?? 'Ardoq - Document';

    const { closeDrawer } = showDocumentPickerDrawer({
      iframeSource: createDocumentPickerUrl({ token, titleSuggestion }),
      onClose: closeDocumentPicker,
    });

    const documetPicker$ = createLucidDocumentPickerStream({
      onExpired: closeDrawer,
    });

    documetPicker$
      .pipe(
        filter(
          data => data?.status === LucidChartDocumentPickerStatus.CANCELLED
        ),
        tap(() => {
          closeDrawer();
          trackEvent(ANALYTIC_EVENTS.CANCELLED_DOCUMENT_PICKER);
        })
      )
      .subscribe();

    documetPicker$
      .pipe(
        tap(data => {
          if (isSuccessDocumentSelection(data)) {
            const targetId = componentId;
            const targetType: ExternalDocumentTargetType = 'component';

            dispatchAction(
              linkDocument({
                documentId: data.documentId,
                targetId,
                targetType,
                onComplete: closeDrawer,
              })
            );

            trackEvent(ANALYTIC_EVENTS.SELECTED_DOCUMENT, {
              targetId,
              targetType,
            });
          }
        })
      )
      .subscribe();
  }),
  catchError(handleArdoqError)
);

const handleExportToLucidchart = routine(
  ofType(exportToLucidchart),
  extractPayload(),
  withLatestFrom(loadedState$, traversalState$, viewModifiers$),
  map(
    ([
      actionPayload,
      loadedState,
      { byId: traversalById },
      {
        mainViewId,
        viewContext: { getGraphComponent },
      },
    ]) => ({
      traversals: loadedState
        .filter(state => isTraversalLoadedState(state))
        .map(({ traversalId }) => traversalId)
        .filter(ExcludeFalsy)
        .map(id => traversalById[id]),
      graph: getGraphComponent(),
      actionPayload,
      viewId: mainViewId,
    })
  ),
  tap(({ graph }) => {
    if (!graph) {
      showToast('Unable to export to Lucidchart document', ToastType.INFO);
    }
  }),
  map(({ traversals, graph, actionPayload, viewId }) => {
    if (!graph) {
      return null;
    }

    const jsonGraph = getJSONGraph(graph);
    const title = getExportLucidDocumentTitle(traversals);
    const isModernizedView = viewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM;

    return {
      title,
      graph: jsonGraph,
      ardoqUrl: window.location.href,
      isModernizedView,
      actionPayload,
    };
  }),
  carry(
    switchMap(request => {
      if (!request) {
        return of(null);
      }

      const { title, graph, ardoqUrl, isModernizedView, actionPayload } =
        request;
      startCreatingDocumentModal();

      trackEvent(ANALYTIC_EVENTS.STARTED_EXPORT_LUCIDCHART_DOCUMENT, {
        nodesCount: graph.nodeList.length,
        edgesCount: graph.edgeList.length,
      });

      return authRequiredLucidchartAction({
        lucidchartAction: () =>
          integrationsLucidChartApi.exportVisualization({
            title,
            graph,
            ardoqUrl,
            isModernizedView,
          }),
        onCompleteAction: exportToLucidchart,
        payload: actionPayload,
        operation: 'export to Lucidchart document',
      });
    })
  ),
  tap(([request, response]) => {
    closeCreatingDocumentModal();
    if (
      isArdoqError(response) &&
      api.isForbidden(response) &&
      response.userMessage === LUCIDCHART_INVALID_SESSION
    ) {
      // nothing to do. User will be redirected to login page
      // events are tracked with OAuth
      return;
    }

    if (!response || isArdoqError(response)) {
      const userMessage = response?.userMessage;
      if (userMessage) {
        showExportErrorAlert(userMessage);
      } else {
        showUnexpectedErrorDialog();
      }
      trackEvent(ANALYTIC_EVENTS.FAILED_EXPORT_LUCIDCHART_DOCUMENT, {
        reason: response?.userMessage ?? 'Request failure',
      });
      return;
    }

    completeCreatingDocument(response);
    trackEvent(ANALYTIC_EVENTS.SUCCESS_EXPORT_LUCIDCHART_DOCUMENT, {
      nodesCount: request?.graph.nodeList.length,
      edgesCount: request?.graph.edgeList.length,
      warningsCount: response.warnings?.length,
    });
  }),
  catchError(err => {
    closeCreatingDocumentModal();
    showUnexpectedErrorDialog();
    trackEvent(ANALYTIC_EVENTS.FAILED_EXPORT_LUCIDCHART_DOCUMENT, {
      reason: 'Application failure',
    });
    return handleArdoqError(err);
  })
);

const onAddToPresentation = (title: string, documentId: string) => {
  dispatchAction(
    addSlideToPresentationDialog({
      newSlide: {
        name: title,
        type: SlideTypes.LUCID,
        lucidDocumentId: documentId,
      },
    })
  );
};

const handleOpenEmbedViewer = routine(
  ofType(openEmbedViewer),
  extractPayload(),
  carry(
    switchMap(payload =>
      authRequiredLucidchartAction({
        lucidchartAction: integrationsLucidChartApi.settings,
        onCompleteAction: openEmbedViewer,
        payload,
        operation: 'open Lucidchart embed viewer',
      })
    )
  ),
  tap(([payload, response]) => {
    const onError = () => {
      window.open(getLucidEditUrl(payload.documentId), '_blank');
    };

    if (isArdoqError(response)) {
      if (
        api.isForbidden(response) &&
        response.userMessage !== LUCIDCHART_INVALID_SESSION
      ) {
        onError();
      }
      return;
    }

    showEmbedViewerDrawer({
      documentId: payload.documentId,
      clientId: response.clientId,
      onError,
      onAddToPresentation: () =>
        onAddToPresentation(payload.title, payload.documentId),
    });
  }),
  catchError(handleArdoqError)
);

const handleLinkDocument = routine(
  ofType(linkDocument),
  extractPayload(),
  withLatestFrom(activeScenario$),
  carry(
    switchMap(
      ([{ documentId, targetId, targetType, onComplete }, { scenarioId }]) =>
        from(
          integrationsLucidChartApi.linkDocument(documentId, {
            targetId,
            targetType,
            scenarioId,
          })
        ).pipe(
          handleError(error => {
            onComplete?.();
            if (api.isConflict(error)) {
              trackEvent(ANALYTIC_EVENTS.FAILED_LINK_DOCUMENT, {
                reason: 'Document already linked',
              });
              showToast('Document already linked', ToastType.INFO);
              return;
            }

            trackEvent(ANALYTIC_EVENTS.FAILED_LINK_DOCUMENT, {
              reason: 'General failure',
            });
            showToast('Failed to link document', ToastType.INFO);
          })
        )
    )
  ),
  tap(([[{ targetId, targetType, onComplete }]]) => {
    onComplete?.();
    trackEvent(ANALYTIC_EVENTS.SUCCESS_LINK_DOCUMENT, { targetId, targetType });
    showToast('Document linked successfully', ToastType.SUCCESS);
  })
);

export default collectRoutines(
  handleOpenDocumentPicker,
  handleOpenEmbedViewer,
  handleExportToLucidchart,
  handleLinkDocument
);
