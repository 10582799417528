import { AddIcon, IconName } from '@ardoq/icons';
import { NavigationMenuGroup } from '../NavigationSidebar/types';
import { AccessControlPageStream } from '../types';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import ManageIPAllowlist from 'admin/manageIPAllowlist/ManageIPAllowlist';
import { SubdivisionOverviewTablePage } from 'admin/SubdivisionOverviewTablePage';
import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { BrandButton } from '@ardoq/button';
import { AccessControlMenuSingleOption } from './types';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';

export const getAssetManagementMenuOption = (
  args: {
    showManageUserRoles: boolean;
    showIPAllowlisting: boolean;
    showSCIM: boolean;
    showSSOAttributeMapping: boolean;
    subdivisionFeature: boolean;
  },
  resources: AccessControlPageStream['resources']
): NavigationMenuGroup =>
  ({
    label: 'Asset management',
    iconName: IconName.ASSET_MANAGEMENT,
    items: [
      args.showIPAllowlisting &&
        ({
          label: 'IP allowlisting',
          id: AccessControlTabs.IP_ALLOWLIST_MANAGEMENT,
          content: () => <ManageIPAllowlist />,
          description:
            'Control access by allowing only specific IP addresses to enterArdoq.',
          knowledgeBaseLink: KnowledgeBaseLink.IP_ALLOWLISTING,
          rightText: resources.ipAllowlist?.entries.length.toString(),
        } satisfies AccessControlMenuSingleOption),
      args.subdivisionFeature &&
        ({
          label: 'Divisions',
          id: AccessControlTabs.SUBDIVISIONS_OVERVIEW,
          description: SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.SUBTITLE,
          content: () => <SubdivisionOverviewTablePage />,
          actionButton: props => (
            <BrandButton onClick={props.commands.createSubdivision}>
              {SUBDIVISIONS_STRINGS.SUBDIVISIONS_EDITOR.CREATE_NEW}
              <AddIcon />
            </BrandButton>
          ),
          rightText: resources.subdivisions?.sortedIds.length.toString(),
        } satisfies AccessControlMenuSingleOption),
    ].filter(ExcludeFalsy),
  }) satisfies NavigationMenuGroup;
