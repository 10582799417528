import { ArdoqId } from '@ardoq/api-types';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { dispatchAction, streamReducer } from '@ardoq/rxbeach';
import { ContextMenuTestIds } from 'contextMenus/consts';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { noop } from 'lodash';
import { combineLatest, from, map } from 'rxjs';
import {
  NavigatorConfiguration,
  NavigatorLayoutState,
} from 'components/WorkspaceHierarchies/types';
import { navigatorTreeOperations } from 'components/WorkspaceHierarchies/reducers/navigatorTreeOperations';
import { navigatorLayoutOperations } from 'components/WorkspaceHierarchies/reducers/navigatorLayoutOperations';
import { registerTextPopovers } from '@ardoq/popovers';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { getCurrentLocale } from '@ardoq/locale';
import { scopeDataOperations, workspaceUtils } from '@ardoq/scope-data';
import { ContextShape } from '@ardoq/data-model';
import { getNavigatorViewInterface } from './streams/getNavigatorViewInterface';
import { contextSort$ } from 'navigator2024/streams/contextSort$';
import { Observable } from 'rxjs';
import {
  loadingScopeDataNotification$,
  loadScopeDataError$,
  scopeData$,
} from './streams/scopeData$';
import { partialPayload, pipeReducers } from '@ardoq/common-helpers';
import { componentTreeSelectionStartSelected } from 'componentSelection/componentSelectionActions';
import { componentHierarchySelection$ } from 'components/WorkspaceHierarchies/streams/componentHierarchySelection$';
import { navigatorSelectionOperations } from 'components/WorkspaceHierarchies/reducers/navigatorSelectionOperations';
import { actionNamespaceComponentHierarchy } from './consts';
import { loadedState$ } from 'loadedState/loadedState$';
import { getHandleSelectionChangeRoutine } from './routines/getHandleSelectionChangeRoutine';
import { InventoryState } from './InventoryState';
import { getCloseAllDataRoutine } from './getCloseAllDataRoutine';
import { navigatorOperations } from 'components/WorkspaceHierarchies/reducers/navigatorOperations';

const inventory$: Observable<InventoryState> = combineLatest({
  scopeData: scopeData$,
  currentUserPermissionContext: currentUserPermissionContext$,
  subdivisionsContext: subdivisions$,
  sortOrder: contextSort$,
}).pipe(
  map(
    ({
      scopeData,
      currentUserPermissionContext,
      subdivisionsContext,
      sortOrder,
    }) => {
      const enhancedScopeData = scopeDataOperations.enhance(scopeData);
      const navigatorInterface = getNavigatorViewInterface(
        enhancedScopeData,
        currentUserPermissionContext,
        subdivisionsContext,
        sortOrder,
        getCurrentLocale()
      );
      const workspacesIds =
        workspaceUtils.getAllWorkspaceIds(enhancedScopeData);
      const context: ContextShape = {
        workspacesIds,
        workspaceId: '',
        componentId: '',
        referenceId: '',
        modelId: '',
        scenarioId: '',
        presentationId: '',
        isExploreMode: false,
        sort: sortOrder,
        connectedWorkspaceIds: [],
      };
      return {
        navigatorInterface,
        context,
        enhancedScopeData,
      };
    }
  )
);

export const inventoryStreamReducer = (
  state: NavigatorLayoutState,
  { navigatorInterface, context }: InventoryState
) => {
  return pipeReducers(
    state,
    partialPayload(
      navigatorTreeOperations.setNavigatorViewInterface,
      navigatorInterface
    ),
    partialPayload(navigatorTreeOperations.setSort, context.sort),
    partialPayload(navigatorTreeOperations.reloadTree),
    partialPayload(navigatorTreeOperations.resyncTree, context)
  );
};

export const componentHierarchyConfiguration: NavigatorConfiguration = {
  eventHandlers: {
    onComponentClick: (componentId: ArdoqId) =>
      dispatchAction(
        componentTreeSelectionStartSelected({ selectionStart: componentId }),
        actionNamespaceComponentHierarchy
      ),
    onWorkspaceClick: (workspaceId: ArdoqId) =>
      alert(`Workspace clicked: ${workspaceId}`),
    onScenarioClick: noop,
    onWorkspaceCloseClick: (workspaceId: ArdoqId) =>
      alert(`Workspace close clicked: ${workspaceId}`),
    onScenarioCloseClick: noop,
    onComponentContextMenu: (
      event: MouseEvent,
      componentIds: ArdoqId[],
      isViewpointMode: boolean
    ) => {
      const items: DropdownItem[] = [
        {
          label: 'Test',
          onClick: () => {
            alert(
              `Test, componentIds: ${componentIds}, isViewpointMode: ${isViewpointMode}`
            );
          },
          type: DropdownOptionType.OPTION,
        },
      ];
      dispatchAction(
        setContextMenuState({
          items,
          position: { left: event.clientX, top: event.clientY },
          testId: ContextMenuTestIds.COMPONENT,
        })
      );
    },
    onWorkspaceContextmenu: (
      event: MouseEvent,
      workspaceIds: ArdoqId[],
      _: ArdoqId | null,
      isViewpointMode: boolean
    ) => {
      const items: DropdownItem[] = [
        {
          label: 'Test',
          onClick: () => {
            alert(
              `Test, workspaceIds: ${workspaceIds}, isViewpointMode: ${isViewpointMode}`
            );
          },
          type: DropdownOptionType.OPTION,
        },
      ];
      dispatchAction(
        setContextMenuState({
          items,
          position: { left: event.clientX, top: event.clientY },
          testId: ContextMenuTestIds.WORKSPACE,
        })
      );
    },
    onScenarioContextmenu: noop,
  },
  additionalRoutines: [
    () =>
      getHandleSelectionChangeRoutine(
        componentHierarchySelection$,
        loadedState$,
        inventory$
      ),
    getCloseAllDataRoutine,
  ],
  externalReducers: [
    streamReducer(inventory$, inventoryStreamReducer),
    streamReducer(
      from([{ isViewpointMode: true }]),
      navigatorLayoutOperations.setIsViewpointMode
    ),
    streamReducer(
      componentHierarchySelection$,
      navigatorSelectionOperations.updateSelection
    ),
    streamReducer(
      loadingScopeDataNotification$,
      navigatorOperations.setDataLoadingMessage
    ),
    streamReducer(loadScopeDataError$, navigatorOperations.setDataErrorMessage),
  ],
  onViewInitialized: () => {
    registerTextPopovers();
  },
  componentSelection$: componentHierarchySelection$,
  actionNamespace: actionNamespaceComponentHierarchy,
  showWorkspaceCloseButton: true,
};
