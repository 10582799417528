import { useEffect } from 'react';
import { AqLayout } from '@ardoq/layout';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { ViewpointsStreamShape } from './types';
import {
  ButtonGroup,
  BrandButton,
  SecondaryButton,
  GhostButton,
} from '@ardoq/button';
import { navigateToViewpoints } from 'router/navigationActions';
import {
  fetchTypeRelationships,
  setViewpointLegacyMode,
  updateDefaultViewpoint,
} from './actions';
import viewpoints$ from './viewpoints$';
import styled from 'styled-components';
import { colors, s24, s8 } from '@ardoq/design-tokens';
import { ChevronLeftIcon, IconName, OpenInNewIcon } from '@ardoq/icons';
import IconClickable from 'atomicComponents/IconClickable';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import ViewpointDefaultsTable from './ViewpointDefaultsTable';
import { DoqLoader } from '@ardoq/snowflakes';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';
import { viewpointAccessControlInterface } from 'resourcePermissions/accessControlHelpers/viewpoints';
import { hasFeature, Features } from '@ardoq/features';
import { map } from 'rxjs';
import MainToolbar from 'menus/topbar/MainToolbar';
import Navbar from 'views/navbar/Navbar';
import { PageBody, PageWrapper } from '@ardoq/page-layout';

const RightAligned = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: fit-content;
  justify-content: flex-end;
`;

type ViewpointDefaultsProps = ViewpointsStreamShape & {
  hasNewJourneyFeature: boolean;
};

const ViewpointDefaults = ({
  workspaces,
  viewpoints,
  typeRelationships,
  isSaving,
  hasNewJourneyFeature,
}: ViewpointDefaultsProps) => {
  useEffect(() => {
    dispatchAction(fetchTypeRelationships(workspaces?.map(w => w._id) ?? []));
  }, [workspaces]);

  const handleChange = (
    componentTypeName: string,
    workspaceId: string,
    viewpointId?: string
  ) =>
    dispatchAction(
      updateDefaultViewpoint({ componentTypeName, workspaceId, viewpointId })
    );

  const pageContent =
    typeRelationships && viewpoints && workspaces ? (
      <ViewpointDefaultsTable
        onChange={handleChange}
        workspaces={workspaces}
        viewpoints={viewpoints}
        typeRelationships={typeRelationships}
        isSaving={isSaving}
      />
    ) : (
      <DoqLoader title="Retrieving Viewpoints data..." />
    );
  return hasNewJourneyFeature ? (
    <PageWrapper>
      <Navbar
        primaryContent="Default viewpoints"
        primaryButton={
          <BrandButton
            onClick={() => window.open(`/discover/`, '_blank')}
            dataTestId="discover-button"
          >
            Go to Discover
            <OpenInNewIcon />
          </BrandButton>
        }
        secondaryButton={
          <GhostButton
            dataTestId="overview-button"
            onClick={() => {
              dispatchAction(setViewpointLegacyMode(false));
              dispatchAction(navigateToViewpoints());
            }}
          >
            <ChevronLeftIcon />
            Back to viewpoints
          </GhostButton>
        }
        secondaryContent="Overview"
        toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
      />
      <PageBody backgroundColor="surfaceDefault">{pageContent}</PageBody>
    </PageWrapper>
  ) : (
    <AqLayout
      title="Default viewpoint overview"
      buttonContainerStyle={{
        flexGrow: 1,
        alignItems: 'center',
      }}
      renderHeaderButtons={() => (
        <>
          <IconClickable
            color={colors.grey50}
            hoverColor={colors.grey35}
            iconName={IconName.KNOWLEDGE_BASE}
            tooltipText="Knowledge base"
            onClick={() =>
              window.open(
                KnowledgeBaseLink.VIEWPOINTS_OVERVIEW,
                '_blank noopener'
              )
            }
            containerStyle={{
              marginLeft: s8,
              height: s24,
            }}
          />
          <RightAligned>
            <ButtonGroup>
              <GhostButton
                onClick={() => window.open(`/discover/`, '_blank')}
                dataTestId="discover-button"
              >
                Go to Discover
                <OpenInNewIcon />
              </GhostButton>
              <SecondaryButton
                dataTestId="overview-button"
                onClick={() => dispatchAction(navigateToViewpoints())}
              >
                Go to viewpoint overview
              </SecondaryButton>
            </ButtonGroup>
          </RightAligned>
        </>
      )}
    >
      {pageContent}
    </AqLayout>
  );
};

const ViewpointDefaultsContainer = (props: ViewpointDefaultsProps) => {
  if (!viewpointAccessControlInterface.canSetDefaults()) {
    return <UnauthorizedAccessPage />;
  }
  return <ViewpointDefaults {...props} />;
};

export default connect(
  ViewpointDefaultsContainer,
  viewpoints$.pipe(
    map(viewpointsStreamData => ({
      ...viewpointsStreamData,
      hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
    }))
  )
);
