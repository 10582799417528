import { ButtonType } from '@ardoq/button';
import { ButtonWithDropdown, DropdownOptionType } from '@ardoq/dropdown-menu';
import { Label as LabelBase } from '@ardoq/forms';
import { s16, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import ComponentQueryBuilder from './ComponentQueryBuilder';
import { toComponentFilter, toComponentQuery } from './utils';
import { useController, useFormContext } from 'react-hook-form';
import { getSelectedComponentTypeNames } from 'viewpoints/utils';
import {
  APIDiscoverViewpointAttributes,
  BooleanOperator,
  QueryBuilderSubquery,
} from '@ardoq/api-types';

const Label = styled(LabelBase)`
  margin-bottom: ${s16};
`;

const ComponentsFilter = () => {
  const {
    field: { onChange },
  } = useController({ name: 'componentFilters' });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [componentFilters, workspaceIds, dynamicGroupedTraversals] = watch([
    'componentFilters',
    'workspaceIds',
    'dynamicGroupedTraversals',
  ]);
  if (!componentFilters) {
    return null;
  }
  const componentQueries = componentFilters.map(toComponentQuery);

  const componentTypeOptions = getSelectedComponentTypeNames(
    dynamicGroupedTraversals
  ).map(componentTypeName => ({
    label: componentTypeName,
    onClick: () => addComponentQuery(componentTypeName),
    isDisabled: componentQueries.find(({ name }) => name === componentTypeName),
  }));

  const addComponentQuery = (componentTypeName: string) => {
    onChange([
      ...componentFilters,
      toComponentFilter({
        name: componentTypeName,
        filters: {
          condition: BooleanOperator.AND,
          rules: [],
        },
      }),
    ]);
  };

  const deleteComponentQuery = (compTypeName: string) => {
    const deletionIndex = componentQueries.findIndex(
      ({ name }) => name === compTypeName
    );

    onChange([
      ...componentFilters.slice(0, deletionIndex),
      ...componentFilters.slice(deletionIndex + 1),
    ]);
  };

  const updateComponentQuery = (
    componentQuery: QueryBuilderSubquery,
    index: number
  ) => {
    const updatedQuery = {
      ...componentQueries[index],
      filters: componentQuery,
    };
    onChange([
      ...componentFilters.slice(0, index),
      toComponentFilter(updatedQuery),
      ...componentFilters.slice(index + 1),
    ]);
  };

  return (
    <>
      <ButtonWithDropdown
        isDisabled={!componentTypeOptions.length}
        style={{ marginBottom: s32 }}
        buttonType={ButtonType.SECONDARY}
        options={[
          { label: 'On component type', type: DropdownOptionType.HEADER },
          ...componentTypeOptions,
        ]}
        dataTestId="add-component-filter-button"
      >
        Set filter
      </ButtonWithDropdown>
      {componentQueries.length ? (
        <>
          <Label>Filter on</Label>
          {componentQueries.map((queryRule, index) => (
            <ComponentQueryBuilder
              key={index}
              componentQuery={queryRule.filters}
              workspaceIds={workspaceIds}
              componentTypeName={queryRule.name}
              onComponentQueryDelete={deleteComponentQuery}
              onComponentQueryChange={componentQuery =>
                updateComponentQuery(componentQuery, index)
              }
            />
          ))}
        </>
      ) : null}
    </>
  );
};

export default ComponentsFilter;
