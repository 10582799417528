import { dispatchAction } from '@ardoq/rxbeach';
import {
  openEmailAudienceDialog,
  openGremlinAudienceDialog,
  openPredefinedAudienceDialog,
  openWorkspaceAudienceDialog,
} from 'broadcasts/actions';
import { BroadcastAudience, BroadcastContentType } from '@ardoq/api-types';
import {
  containsEmailAudience,
  containsGremlinAudience,
  containsPredefinedAudience,
  containsWorkspaceAudience,
} from 'broadcasts/utils';
import { ButtonType } from '@ardoq/button';
import { ButtonWithDropdown, DropdownOptionType } from '@ardoq/dropdown-menu';

const getTargetedAudienceOptions = (
  audiences: BroadcastAudience[],
  canRunGremlinQueries: boolean
) => [
  {
    label: 'For targeted audience',
    type: DropdownOptionType.HEADER,
  },
  {
    label: 'By predefined people query',
    onClick: () => dispatchAction(openPredefinedAudienceDialog()),
    isDisabled: containsPredefinedAudience(audiences),
  },
  {
    label: 'By Gremlin people query',
    description: canRunGremlinQueries
      ? undefined
      : 'Requires Create Gremlin privileges',
    onClick: () => dispatchAction(openGremlinAudienceDialog()),
    isDisabled: containsGremlinAudience(audiences) || !canRunGremlinQueries,
  },
];

const getGeneralAudienceOptions = (
  audiences: BroadcastAudience[],
  canRunGremlinQueries: boolean
) => [
  {
    label: 'For general audience',
    type: DropdownOptionType.HEADER,
  },
  {
    label: 'From group or individual email',
    onClick: () => dispatchAction(openEmailAudienceDialog()),
    isDisabled: containsEmailAudience(audiences),
  },
  {
    label: 'From people workspace in Ardoq',
    onClick: () => dispatchAction(openWorkspaceAudienceDialog()),
    isDisabled: containsWorkspaceAudience(audiences) || !canRunGremlinQueries,
    description: canRunGremlinQueries
      ? undefined
      : 'Requires Create Gremlin privileges',
  },
];

const getAllAudienceOptions = (
  audiences: BroadcastAudience[],
  canRunGremlinQueries: boolean
) => [
  ...getTargetedAudienceOptions(audiences, canRunGremlinQueries),
  ...getGeneralAudienceOptions(audiences, canRunGremlinQueries),
];

type AudienceOptionsDropdownProps = {
  readonly contentType: BroadcastContentType;
  readonly audiences: BroadcastAudience[];
  readonly disabled?: boolean;
  canRunGremlinQueries: boolean;
};

const AudienceOptionsDropdown = ({
  contentType,
  audiences,
  canRunGremlinQueries,
  disabled,
}: AudienceOptionsDropdownProps) => (
  <ButtonWithDropdown
    buttonType={ButtonType.SECONDARY}
    alignMenuTo="left"
    options={
      contentType === BroadcastContentType.REPORT
        ? getGeneralAudienceOptions(audiences, canRunGremlinQueries)
        : getAllAudienceOptions(audiences, canRunGremlinQueries)
    }
    isDisabled={disabled}
    dataTestId="audience-select"
  >
    Add audience
  </ButtonWithDropdown>
);

export default AudienceOptionsDropdown;
