import { useMemo } from 'react';
import { ArdoqId, ViewpointDetailEntry } from '@ardoq/api-types';
import { Label } from '@ardoq/forms';
import { SortableMultiselect } from '@ardoq/select';
import { s16 } from '@ardoq/design-tokens';
import { FieldSelectOption } from 'viewpoints/types';
import { SecondaryButton } from '@ardoq/button';
import { correctDateRangeFieldValues } from '../utils';
import {
  getComponentCustomFieldOptions,
  getReferenceCustomFieldOptions,
} from 'viewpoints/utils';
import { Icon, IconName } from '@ardoq/icons';
import styled from 'styled-components';
import { TextOverflow } from '@ardoq/popovers';
import { TAGS_FIELD_NAME } from '@ardoq/data-model';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dateRangeOperations } from '@ardoq/date-range';

const referenceAndComponentAttributeOptions: FieldSelectOption[] = [
  {
    value: 'createdByEmail',
    label: 'Created by (email)',
    category: 'attribute',
  },
  {
    value: 'createdByName',
    label: 'Created by (name)',
    category: 'attribute',
  },
  {
    value: 'created',
    label: 'Created date',
    category: 'attribute',
  },
  {
    value: 'lastModifiedByEmail',
    label: 'Last updated by (email)',
    category: 'attribute',
  },
  {
    value: 'lastModifiedByName',
    label: 'Last updated by (name)',
    category: 'attribute',
  },
  {
    value: 'lastUpdated',
    label: 'Last updated date',
    category: 'attribute',
  },
  {
    value: TAGS_FIELD_NAME,
    label: 'Tags',
    category: 'attribute',
  },
];

const componentAttributeOptions: FieldSelectOption[] = [
  {
    value: 'component-key',
    label: 'Ardoq Id',
    category: 'attribute',
  },
  ...referenceAndComponentAttributeOptions,
];

type DetailEntryFormProps = {
  entityName: string;
  detailEntry: ViewpointDetailEntry;
  detailEntryType: 'component' | 'reference';
  workspaceIds: ArdoqId[];
  onDetailEntryChange: (detailEntry: ViewpointDetailEntry) => void;
};

const StyledSortableMultiselect = styled(SortableMultiselect)`
  width: 448px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${s16};
`;

const DetailEntryForm = ({
  detailEntry,
  entityName,
  detailEntryType,
  workspaceIds,
  onDetailEntryChange,
}: DetailEntryFormProps) => {
  const attributeOptions =
    detailEntryType === 'component'
      ? componentAttributeOptions
      : referenceAndComponentAttributeOptions;

  const { customFieldOptions, combinedOptions } = useMemo(() => {
    const nextCustomFieldOptions =
      detailEntryType === 'component'
        ? getComponentCustomFieldOptions(workspaceIds, entityName)
        : getReferenceCustomFieldOptions(workspaceIds, entityName);

    return {
      customFieldOptions: nextCustomFieldOptions,
      combinedOptions: [...attributeOptions, ...nextCustomFieldOptions],
    };
  }, [detailEntryType, workspaceIds, entityName, attributeOptions]);

  const optionNames =
    detailEntry.scope !== 'none'
      ? (detailEntry?.selectedFields ?? []).map(({ name }) => name)
      : [];

  const tags = detailEntry.includeTags ? [TAGS_FIELD_NAME] : [];

  const selectedFieldOptions: FieldSelectOption[] = [...optionNames, ...tags]
    .map(name => {
      return combinedOptions.find(
        option =>
          option.value === name ||
          dateRangeOperations.toStartDateName(option.value) === name
      );
    })
    .filter(ExcludeFalsy);

  const entryTypeData =
    detailEntryType === 'component'
      ? {
          componentTypeName: entityName,
        }
      : {
          referenceTypeName: entityName,
        };

  const allOptionsMinusTags = [
    ...attributeOptions,
    ...customFieldOptions,
  ].filter(opt => opt.value !== TAGS_FIELD_NAME);

  const handleSelectAllDetails = () => {
    onDetailEntryChange({
      ...entryTypeData,
      useFieldDescriptions: detailEntry.useFieldDescriptions,
      scope: 'all',
      includeTags: true,
      selectedFields: correctDateRangeFieldValues(allOptionsMinusTags),
    });
  };

  const handleSelectDetails = (options: FieldSelectOption[] | null) => {
    if (!options || !options.length) {
      return onDetailEntryChange({
        ...entryTypeData,
        useFieldDescriptions: detailEntry.useFieldDescriptions,
        scope: 'none',
        includeTags: false,
      });
    }
    const fieldAndAttributeOptions = options.filter(
      opt => opt.value !== TAGS_FIELD_NAME
    );

    if (options.length === attributeOptions.length + customFieldOptions.length)
      return onDetailEntryChange({
        ...detailEntry,
        scope: 'all',
        selectedFields: correctDateRangeFieldValues(fieldAndAttributeOptions),
        includeTags: Boolean(
          options.find(opt => opt.value === TAGS_FIELD_NAME)
        ),
      });

    onDetailEntryChange({
      ...detailEntry,
      scope: 'selected',
      selectedFields: correctDateRangeFieldValues(fieldAndAttributeOptions),
      includeTags: Boolean(options.find(opt => opt.value === TAGS_FIELD_NAME)),
    });
  };

  return (
    <div>
      <Label width={'450px'}>
        <TextOverflow>{entityName}</TextOverflow>
      </Label>
      <Row>
        <StyledSortableMultiselect
          options={[
            {
              label: 'Default Fields',
              options: attributeOptions,
            },
            {
              label: 'Custom Fields',
              options: customFieldOptions,
            },
          ]}
          placeholder="Type to search..."
          value={selectedFieldOptions}
          onChange={options =>
            handleSelectDetails(options as FieldSelectOption[] | null)
          }
          dataTestId="field-select"
        />
        <SecondaryButton
          onClick={handleSelectAllDetails}
          isDisabled={detailEntry.scope === 'all'}
          dataTestId="add-all-button"
        >
          <Icon iconName={IconName.ADD} />
          Add all
        </SecondaryButton>
      </Row>
    </div>
  );
};

export default DetailEntryForm;
