import { CSSProperties, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { CheckboxIcon, Icon, IconName, IconSize } from '@ardoq/icons';
import { Selected } from 'aqTypes';
import { times } from 'lodash';
import { colors, s16, spacing } from '@ardoq/design-tokens';
import { RowType } from '@ardoq/table';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { Link } from '@ardoq/typography';
import { PopoverPlacement, TextOverflow } from '@ardoq/popovers';
import { FlexBox } from '@ardoq/layout';
import { Button, ButtonType, UnstyledButton } from '@ardoq/button';
import currentUser$ from 'streams/currentUser/currentUser$';
import { connect, derivedStream, dispatchAction } from '@ardoq/rxbeach';
import { SurveyRow } from 'components/AssetsBrowser/types';
import { useEffectOnce } from '@ardoq/hooks';
import {
  issueSurveyWorkspaceAccessRequest,
  resetSurveyWorkspaceAccessRequestState,
} from './streams/actions';
import entityBrowserAtoms$ from './streams/entityBrowserAtoms$';
import { map } from 'rxjs';

const CheckboxIconStyled = styled(CheckboxIcon)`
  vertical-align: middle;
  margin-right: ${spacing.s4};
  cursor: pointer;
`;

type CheckboxProps = {
  style?: CSSProperties;
  onClick: (e: MouseEvent<any>) => void;
  disabled?: boolean;
  isSelected: Selected;
};
export const Checkbox = ({ isSelected, onClick, ...props }: CheckboxProps) => (
  <CheckboxIconStyled
    checked={isSelected === Selected.SELECTED}
    onClick={onClick}
    mixed={isSelected === Selected.MIXED ? true : undefined}
    {...props}
  />
);

const FolderIconStyled = styled(Icon)`
  color: ${colors.grey35};
  fill: ${colors.grey35};
  cursor: pointer;
  min-width: 24px;

  &:hover {
    color: ${colors.grey25};
    fill: ${colors.grey25};
  }
`;

type FolderIconProps = {
  isOpened?: boolean;
  onClick?: () => void;
};
export const FolderIcon = ({ isOpened, ...props }: FolderIconProps) => (
  <FolderIconStyled
    iconName={isOpened ? IconName.OPENED_FOLDER : IconName.FOLDER}
    {...props}
  />
);

export const RowIconWrapper = styled.span<{ $isDisabled?: boolean }>`
  margin-right: ${spacing.s4};
  margin-left: ${spacing.s4};
  svg {
    height: 20px;
  }

  ${FolderIconStyled} {
    height: 24px;
  }

  opacity: ${({ $isDisabled = false }) => ($isDisabled ? 0.5 : null)};
`;

const BaseRowIconStyles = styled(Icon).attrs({
  iconSize: IconSize.REGULAR,
})`
  min-width: 24px;
  color: ${colors.grey35};
  fill: ${colors.grey35};
`;

const rowTypeToColor: Record<RowType, string> = {
  [RowType.WORKSPACE]: colors.purple25,
  [RowType.MANAGED_WORKSPACE]: colors.purple25,
  [RowType.TRAVERSAL]: colors.brand50,
  [RowType.BOOKMARK]: colors.pink80, // TODO AM bookmarks discuss with designer
  [RowType.PRESENTATION]: colors.pink50,
  [RowType.METAMODEL]: colors.yellow60,
  [RowType.SURVEY]: colors.blue50,
  [RowType.SCENARIO]: colors.green35,
  [RowType.REPORT]: 'blue',
  [RowType.DASHBOARD]: 'crimson',
  [RowType.VIEWPOINT]: colors.pink60,
  [RowType.BROADCAST]: colors.blue60,
  // DocumentArchive
  [RowType.IMAGE]: colors.grey25,
  [RowType.DOCUMENT]: colors.grey25,
  [RowType.UNKOWN_FILE]: colors.grey25,
  // not really needed, but we want to make TS happy
  [RowType.FOLDER]: '#000',
  [RowType.EMPTY_FOLDER]: '#000',
  [RowType.COMPONENT_TYPE]: '#000',
};

const colorIcon = (assetType: RowType) => css`
  fill: ${rowTypeToColor[assetType]};
  color: ${rowTypeToColor[assetType]};
`;

export const WorkspaceIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.WORKSPACE,
})`
  ${colorIcon(RowType.WORKSPACE)}
  opacity: 0.6;
`;

export const ManagedWorkspaceIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.MANAGED_WORKSPACE,
})`
  ${colorIcon(RowType.WORKSPACE)};
  opacity: 0.6;
`;

export const PresentationIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.PRESENTATION,
})`
  ${colorIcon(RowType.PRESENTATION)}
  opacity: 0.6;
`;

export const MetamodelIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.METAMODELS,
})`
  ${colorIcon(RowType.METAMODEL)}
  opacity: 0.6;
`;

export const SurveyIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.SURVEYS,
})`
  ${colorIcon(RowType.SURVEY)}
  opacity: 0.6;
`;

export const ScenarioIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.SCENARIO,
})`
  ${colorIcon(RowType.SCENARIO)}
  opacity: 0.6;
`;

export const ReportIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.DESCRIPTION,
})`
  ${colorIcon(RowType.REPORT)}
  opacity: 0.6;
`;

export const DashboardIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.PIE_CHART_FILLED,
})`
  ${colorIcon(RowType.DASHBOARD)}
  opacity: 0.6;
`;

export const UnknownFileIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.MISC,
})`
  ${colorIcon(RowType.UNKOWN_FILE)}
`;

export const TraversalIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.ROCKET,
})`
  ${colorIcon(RowType.TRAVERSAL)}
`;

export const ViewpointIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.VIEWPOINT,
})`
  ${colorIcon(RowType.VIEWPOINT)}
`;

export const BroadcastIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.BROADCAST,
})`
  ${colorIcon(RowType.BROADCAST)}
`;

export const BookmarkIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.BOOKMARK,
})`
  ${colorIcon(RowType.BOOKMARK)}
`;

export const DocumentIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.SURVEYS,
})`
  ${colorIcon(RowType.DOCUMENT)}
`;

export const ImageIcon = styled(BaseRowIconStyles).attrs({
  iconName: IconName.COLLECTIONS,
})`
  ${colorIcon(RowType.IMAGE)}
`;

export const ClickableName = styled(UnstyledButton)<{
  onClick?: () => void;
  $isClickDisabled: boolean;
}>`
  flex: 1 1 100%;
  min-width: 200px;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;

      &:hover {
        color: ${colors.grey25};
        text-decoration: underline;

        svg {
          fill: ${colors.grey25};
        }
      }
    `}
  color: ${({ $isClickDisabled }) =>
    $isClickDisabled ? colors.grey50 : undefined}
`;

export const AqInlineEditInputWrapper = styled.span`
  width: 100%;
  position: relative;
  top: 2px;
`;

export const AqInlineEditInput = styled.input`
  color: ${colors.grey35};
  box-shadow: ${colors.grey95} 0px 1px 0px 0px;
  caret-color: ${colors.blue50};
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1.5em;
  outline: none;
  transition: all 200ms ease-in-out 0s;

  &:focus {
    box-shadow: ${colors.blue50} 0px 2px 0px 0px;
  }
`;

export const TextEllipsisWrapper = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TextEllipsisWrapperWithPopover = styled(TextOverflow)<{
  $width: string;
}>`
  word-break: break-word;
  width: ${props => props.$width};
`;

export const NameCellWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  overflow: hidden;

  & > span {
    align-items: center;
    display: flex;
  }
`;

const Push = styled.div`
  display: inline-block;
  width: 39px;
  min-width: 39px;
`;

export const SortWrapper = styled(FlexBox).attrs({ align: 'center' })`
  cursor: pointer;

  i {
    color: inherit;
    vertical-align: middle;
    margin-bottom: 4px;
  }

  &:hover {
    color: #000;
  }
`;

type IndentationProps = { level: number };
export const Indentation = ({ level }: IndentationProps) => (
  <>
    {times(level, i => (
      <Push key={i} />
    ))}
  </>
);

export const NameWrapper = styled.div<{ $indentationLevel: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(
    100% - 80px - 40px * ${props => props.$indentationLevel}
  ); /* space taken by checkbox and asset icon and indentation */
  position: relative; /* container for LinkOverAssetName */
`;

export const LinkOverAssetName = styled.div`
  position: absolute;
  right: 0;
  background-color: ${colors.grey95};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchPhrase = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.grey15};
`;

const DisabledSurveyPopoverContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${s16};
  max-width: 270px;
`;

const getReasonPhrase = (reason: DisabledSurveyReason) => {
  switch (reason) {
    case 'insufficientSurveyPermission':
      return `You can submit answers by clicking the "Answer Survey" button on
      the right. Configuring the survey itself requires "Writing"
      permissions, which can be granted by the survey administrator(s).`;
    case 'insufficientWorkspaceAdminPermission':
      return `You can submit answers by clicking the "Answer Survey" button on the right.
      Configuring the survey itself requires administrative privileges on
      the selected workspace for the survey, which can be granted by the workspace administrator(s).`;
  }
};

type DisabledSurveyReason =
  | 'insufficientSurveyPermission'
  | 'insufficientWorkspaceAdminPermission';
const DisabledSurveyPopoverContent = ({
  reason,
  survey,
  name,
  email,
  surveyWorkspaceAccessRequestSent,
  surveyWorkspaceAccessRequestLoading,
}: {
  reason: DisabledSurveyReason;
  surveyWorkspaceAccessRequestSent: boolean;
  surveyWorkspaceAccessRequestLoading: boolean;
  survey?: SurveyRow;
  name?: string | null;
  email?: string | null;
}) => {
  useEffectOnce(() => {
    dispatchAction(resetSurveyWorkspaceAccessRequestState());
  });

  const onRequestAccess = async () => {
    dispatchAction(
      issueSurveyWorkspaceAccessRequest({
        survey,
        user: { name, email },
      })
    );
  };

  return (
    <DisabledSurveyPopoverContentContainer>
      <p>{getReasonPhrase(reason)}</p>
      {reason === 'insufficientSurveyPermission' && (
        <Link target="_blank" href={KnowledgeBaseLink.USER_ASSET_ACCESS}>
          Read more about permissions.
        </Link>
      )}
      {reason === 'insufficientWorkspaceAdminPermission' && (
        <Button
          isLoading={surveyWorkspaceAccessRequestLoading}
          onClick={onRequestAccess}
          buttonType={ButtonType.BRAND}
          isDisabled={
            surveyWorkspaceAccessRequestLoading ||
            surveyWorkspaceAccessRequestSent
          }
          isFlexible
        >
          {!surveyWorkspaceAccessRequestSent
            ? 'Request workspace access'
            : 'Request sent'}
        </Button>
      )}
    </DisabledSurveyPopoverContentContainer>
  );
};

const ConnectedDisabledSurveyPopoverContent = connect(
  DisabledSurveyPopoverContent,
  derivedStream(
    'disabledSurveyPopoverContent$',
    entityBrowserAtoms$,
    currentUser$
  ).pipe(
    map(
      ([
        {
          surveyWorkspaceAccessRequestSent,
          surveyWorkspaceAccessRequestLoading,
        },
        { name, email },
      ]) => ({
        surveyWorkspaceAccessRequestSent,
        surveyWorkspaceAccessRequestLoading,
        name,
        email,
      })
    )
  )
);

export const getPopoverConfig = ({
  showPopover = false,
  reason,
  survey,
}: {
  showPopover?: boolean;
  reason: DisabledSurveyReason;
  survey?: SurveyRow;
}) => ({
  preferredPlacement: PopoverPlacement.TOP,
  content: showPopover
    ? () => (
        <ConnectedDisabledSurveyPopoverContent
          reason={reason}
          survey={survey}
        />
      )
    : undefined,
});
