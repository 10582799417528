import { BroadcastContentType, BroadcastContent } from '@ardoq/api-types';
import MessageContent from 'broadcasts/broadcastBuilder/broadcastContentForm/MessageContent';
import SurveyContent from 'broadcasts/broadcastBuilder/broadcastContentForm/SurveyContent';
import FilterConditionsSection from 'broadcasts/broadcastBuilder/broadcastContentForm/filterConditionsSection/FilterConditionsSection';
import { VisibilityIcon } from '@ardoq/icons';
import { Select } from '@ardoq/select';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { chooseBroadcastContentType } from 'broadcasts/actions';
import {
  getIconForContentType,
  isMessageContent,
  isReportContent,
  isSurveyContent,
  isValidContent,
} from 'broadcasts/utils';
import { openComponentPreviewDialog } from 'broadcasts/broadcastBuilder/broadcastContentForm/componentPreview/actions';
import broadcastContentForm$, {
  BroadcastContentFormStreamShape,
} from './broadcastContentForm$';
import { SecondaryButton } from '@ardoq/button';
import { Field, FormSize, FormWrapper } from '@ardoq/forms';
import ReportContent from './ReportContent';
import ReportRulesSection from './reportRulesSection/ReportRulesSection';
import { Island } from '@ardoq/page-layout';

type ContentTypeSelectorProps = {
  readonly selectedContentType: BroadcastContentType;
};
type BroadcastContentFormProps = {
  readonly content: BroadcastContent;
} & BroadcastContentFormStreamShape;

const contentTypeOptions = [
  {
    value: BroadcastContentType.MESSAGE,
    label: 'Message',
    iconName: getIconForContentType(BroadcastContentType.MESSAGE),
  },
  {
    value: BroadcastContentType.SURVEY,
    label: 'Survey',
    iconName: getIconForContentType(BroadcastContentType.SURVEY),
  },
  {
    value: BroadcastContentType.REPORT,
    label: 'Report alert',
    iconName: getIconForContentType(BroadcastContentType.REPORT),
  },
];

const ContentTypeSelector = ({
  selectedContentType,
}: ContentTypeSelectorProps) => {
  return (
    <div style={{ flexBasis: '280px' }}>
      <Select
        options={contentTypeOptions}
        value={
          contentTypeOptions.find(
            ({ value: contentType }) => contentType === selectedContentType
          ) ?? null
        }
        onChange={option => {
          if (!option) return;
          if (option.value !== selectedContentType) {
            dispatchAction(
              chooseBroadcastContentType(option.value as BroadcastContentType)
            );
          }
        }}
      />
    </div>
  );
};

const BroadcastContentForm = ({
  content,
  validation: { showRequiredFields },
}: BroadcastContentFormProps) => {
  return (
    <Island
      data-intercom-target="broadcast-content"
      title="1. Select content"
      subtitle="Select the content you want to broadcast. Add filter rules to send Broadcasts under certain conditions."
    >
      <FormWrapper $gap="s32" $formSize={FormSize.AUTO}>
        <Field label="Content type" formSize={FormSize.DEFAULT}>
          <ContentTypeSelector selectedContentType={content.contentType} />
        </Field>
        {isMessageContent(content) ? (
          <MessageContent
            required={showRequiredFields}
            selectedWorkspace={content.workspaceId}
            selectedComponentType={content.componentType}
          />
        ) : isSurveyContent(content) ? (
          <SurveyContent
            required={showRequiredFields}
            selectedSurvey={content.contentId}
          />
        ) : (
          <ReportContent
            required={showRequiredFields}
            selectedReport={content.contentId}
          />
        )}
        {isReportContent(content) ? (
          <ReportRulesSection selectedReport={content.contentId} />
        ) : (
          <>
            <FilterConditionsSection
              selectedContentType={content.contentType}
              isDisabled={!isValidContent(content)}
            />

            <div>
              <SecondaryButton
                onClick={() =>
                  dispatchAction(openComponentPreviewDialog(content))
                }
                isDisabled={!isValidContent(content)}
                data-click-id="open-component-preview-dialog"
                dataTestId="component-preview-button"
              >
                <VisibilityIcon />
                Preview included components
              </SecondaryButton>
            </div>
          </>
        )}
      </FormWrapper>
    </Island>
  );
};

export default connect(BroadcastContentForm, broadcastContentForm$);
