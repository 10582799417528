import { Component } from 'react';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import Context from 'context';
import { Features, hasFeature } from '@ardoq/features';
import togglePresentationEditor$ from 'appContainer/togglePresentationEditor$';
import {
  hideRightPane,
  showRightPane,
  togglePresentationEditor,
} from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import selectedModule$ from 'appContainer/selectedModule$';
import { map } from 'rxjs/operators';
import { ICON_SELECTOR, IconName } from '@ardoq/icons';
import { scopeDiff$ } from 'scope/scopeDiff$';
import DocumentBrowserStackPage from 'components/DocumentBrowser/DocumentBrowserStackPage';
import { pushStackPage } from '@ardoq/stack-page-manager';
import { BEAMER_TRIGGER_ICON, BEAMER_TRIGGER_ID, BeamerStyle } from 'getBeamer';
import { Subscription, combineLatest } from 'rxjs';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import InAppNotifications from './InAppNotifications/InAppNotifications';
import currentUser$ from 'streams/currentUser/currentUser$';
import { getIsOrgAdmin } from '@ardoq/common-helpers';
import { currentUserAccessControlInterface } from 'resourcePermissions/accessControlHelpers/currentUser';
import { hideQuickSearch, showQuickSearch } from './actions';
import { isQuickSearchVisible$ } from './isQuickSearchVisible$';
import { ButtonSize, IconButton } from '@ardoq/button';
import { trackClickedToolbarButton } from 'tracking/events/clicks';
import { Space } from '@ardoq/style-helpers';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { s16, colors } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';
import QuickSearch from 'search/quickSearch/QuickSearch';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { SelectOption } from '@ardoq/select';
import { fieldOptions$ } from 'streams/fields/fieldOptions$';
import { showPresentationEditor } from 'showPresentationEditor';
import { DarkModeToggle } from './DarkModeToggle';
import { contextPresentation$ } from 'streams/presentations/contextPresentation$';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import presentations$ from 'streams/presentations/presentations$';
import { ArdoqId } from '@ardoq/api-types';
import { NeedHelpButton } from './NeedHelpButton';
import { workspaceInterface } from '@ardoq/workspace-interface';

const canShowQuickSearch = (
  hasNewJourneyFeature: boolean,
  isScenarioMode: boolean,
  isViewpointMode: boolean,
  selectedModule: AppModules
) => {
  if (!hasNewJourneyFeature) {
    return (
      (selectedModule === AppModules.WORKSPACES ||
        selectedModule === AppModules.HOME) &&
      !isScenarioMode
    );
  }
  if (
    isScenarioMode ||
    (isViewpointMode && AppModules.WORKSPACES === selectedModule)
  )
    return false;
  return true;
};

const viewModel$ = combineLatest([
  presentations$,
  contextPresentation$,
  selectedModule$,
  togglePresentationEditor$,
  scopeDiff$,
  currentUser$,
  isQuickSearchVisible$,
  activeScenario$,
  loadedGraph$,
  fieldOptions$,
]).pipe(
  map(
    ([
      presentationsState,
      presentation,
      { selectedModule },
      presentationToggled,
      { scopeDiff },
      currentUser,
      isQuickSearchVisible,
      { isScenarioMode },
      { isViewpointMode },
      { customFields, ardoqIdField, ardoqOidField },
    ]) => {
      const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);
      return {
        hasPresentations: Boolean(
          presentationOperations.getPresentationsList(presentationsState).length
        ),
        presentationId: presentation?._id,
        selectedModule,
        presentationToggled: Boolean(presentationToggled && presentation),
        isScopeDiffMode: Boolean(scopeDiff),
        isAdmin: getIsOrgAdmin(currentUser),
        isWriter: currentUserAccessControlInterface.canCurrentUserWrite(),
        hasNewJourneyFeature,
        isQuickSearchVisible,
        customFields: [ardoqIdField, ardoqOidField, ...customFields],
        canShowQuickSearch: canShowQuickSearch(
          hasNewJourneyFeature,
          isScenarioMode,
          isViewpointMode,
          selectedModule
        ),
      };
    }
  )
);

const MainToolbarWrapper = styled(Space).attrs({
  $gap: 's4',
  $align: 'center',
  $flex: 1,
  $justify: 'flex-end',
})<{ $shouldUseNewJourneyVersion: boolean }>`
  ${({ $shouldUseNewJourneyVersion }) =>
    !$shouldUseNewJourneyVersion &&
    css`
      padding-right: ${s16};
      ${ICON_SELECTOR} {
        fill: ${colors.white};
        color: ${colors.white};
      }
    `}
`;

type MainToolbarProps = {
  selectedModule: AppModules;
  presentationToggled: boolean;
  isScopeDiffMode: boolean;
  isAdmin: boolean;
  isWriter: boolean;
  shouldUseNewJourneyVersion: boolean;
  isQuickSearchVisible: boolean;
  customFields: SelectOption<string>[];
  canShowQuickSearch: boolean;
  hasPresentations: boolean;
  presentationId: ArdoqId | undefined;
};

type MainToolbarState = {
  rightPaneToggled: boolean;
};

class MainToolbar extends Component<MainToolbarProps, MainToolbarState> {
  private subscriptions: Subscription[] = [];

  state = {
    rightPaneToggled: false,
  };

  handleOptionOpenedEvent = () => {
    this.setState({
      rightPaneToggled: true,
    });
    dispatchAction(
      togglePresentationEditor({
        shouldShow: false,
      })
    );
  };

  handleOptionClosedEvent = () => {
    this.setState({ rightPaneToggled: false });
  };

  toggleOptions = () => {
    if (this.state.rightPaneToggled) {
      dispatchAction(hideRightPane());
    } else {
      dispatchAction(showRightPane({ type: GetContentOptionsType.MENU }));
    }
  };

  componentDidMount() {
    this.subscriptions = [
      subscribeToAction(showRightPane, this.handleOptionOpenedEvent),
      subscribeToAction(hideRightPane, this.handleOptionClosedEvent),
    ];
  }

  componentWillUnmount() {
    this.subscriptions = clearSubscriptions(this.subscriptions);
  }

  render() {
    const {
      presentationId,
      hasPresentations,
      selectedModule,
      isScopeDiffMode,
      isAdmin,
      isWriter,
      shouldUseNewJourneyVersion,
      isQuickSearchVisible,
      customFields,
      canShowQuickSearch,
      presentationToggled,
    } = this.props;

    const hasPresentationEditor = hasFeature(Features.PRESENTATIONS);
    const hasDarkModeToggle = hasFeature(Features.DARK_MODE_TOGGLE);
    const isWorkspaceModule = selectedModule === AppModules.WORKSPACES;
    const isMetamodelModule = selectedModule === AppModules.METAMODEL;
    const isDashboardModule = selectedModule === AppModules.DASHBOARDS;
    const isReportModule = selectedModule === AppModules.REPORTS;
    const isAccessControlModule = selectedModule === AppModules.ACCESS_CONTROL;

    const contextWorkspaceId = Context.activeWorkspaceId() || '';
    const hasWriteAccess =
      workspaceInterface.hasWriteAccess(contextWorkspaceId);
    const isMenuButtonDisabled = isScopeDiffMode;
    const isValidPresentationModule =
      isWorkspaceModule ||
      isDashboardModule ||
      isMetamodelModule ||
      isReportModule;

    const showInAppNotifications =
      isAdmin || isWriter || hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2);
    const rightPaneTooltip = isMenuButtonDisabled
      ? 'Close visual diff mode to access the menu'
      : 'Sidebar Menu';
    return (
      <MainToolbarWrapper
        $shouldUseNewJourneyVersion={shouldUseNewJourneyVersion}
      >
        <BeamerStyle />
        {shouldUseNewJourneyVersion && isQuickSearchVisible && (
          <QuickSearch
            placeholderText="Search"
            customFields={customFields}
            hasNewJourneyFeature
          />
        )}

        {shouldUseNewJourneyVersion && canShowQuickSearch && (
          <IconButton
            iconName={IconName.SEARCH}
            data-tooltip-text="Quick search"
            onClick={() => {
              if (isQuickSearchVisible) {
                dispatchAction(hideQuickSearch());
              } else {
                dispatchAction(showQuickSearch());
              }
              trackClickedToolbarButton('Search');
            }}
            isActive={isQuickSearchVisible}
            buttonSize={ButtonSize.SMALL}
          />
        )}

        <IconButton
          iconName={BEAMER_TRIGGER_ICON}
          data-tooltip-text="Product updates"
          // Click handling is handled by 3rd-party script
          onClick={() => trackClickedToolbarButton('Product updates')}
          isActive={false}
          dataTestId={BEAMER_TRIGGER_ID}
          buttonSize={ButtonSize.SMALL}
          id={BEAMER_TRIGGER_ID}
        />

        {showInAppNotifications && <InAppNotifications />}
        {shouldUseNewJourneyVersion && hasDarkModeToggle && <DarkModeToggle />}
        {isValidPresentationModule && hasPresentationEditor && (
          <IconButton
            onClick={() =>
              showPresentationEditor({
                contextPresentationId: presentationId,
                hasPresentations,
                selectedModule,
                shouldShow: !presentationToggled,
              })
            }
            data-tooltip-text="Presentation editor"
            iconName={IconName.PRESENTATION}
            isActive={this.props.presentationToggled}
            buttonSize={ButtonSize.SMALL}
          />
        )}

        {isWorkspaceModule && hasWriteAccess && (
          <IconButton
            onClick={() => {
              pushStackPage(DocumentBrowserStackPage);
              trackClickedToolbarButton('Document Archive');
            }}
            data-tooltip-text="Document Archive"
            iconName={IconName.INVENTORY}
            isActive={false}
            buttonSize={ButtonSize.SMALL}
          />
        )}

        {isWorkspaceModule && (
          <IconButton
            onClick={() => {
              this.toggleOptions();
              trackClickedToolbarButton(rightPaneTooltip);
            }}
            data-tooltip-text={rightPaneTooltip}
            isDisabled={isMenuButtonDisabled}
            iconName={IconName.MENU}
            isActive={this.state.rightPaneToggled}
            buttonSize={ButtonSize.SMALL}
            dataTestId="sidebar-menu"
          />
        )}
        {isAccessControlModule && <NeedHelpButton />}
      </MainToolbarWrapper>
    );
  }
}

export default connect(MainToolbar, viewModel$);
