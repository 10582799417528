import { ButtonType } from '@ardoq/button';
import { memo } from 'react';
import {
  ViewIds,
  APIPresentationAssetAttributes,
  APISlideAttributes,
  isVisualizationSlide,
  VisualizationSlide,
} from '@ardoq/api-types';
import { ButtonSize, SmallSecondaryButton } from '@ardoq/button';
import { DoqFace, DoqSize } from '@ardoq/doq';
import { hasFeature, Features } from '@ardoq/features';
import { Icon, IconName, IconSize, ChevronIconName } from '@ardoq/icons';
import { FlexBox, Box } from '@ardoq/layout';
import { IconBadge, StatusType, Tag } from '@ardoq/status-ui';
import { RefObject } from 'react';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';
import viewInterface from 'streams/views/mainContent/viewInterface';
import { getDoqTypeForView } from 'viewDeprecation/util';
import viewStatusBrands from 'views/viewStatusBrands';
import viewStatusLabels from 'views/viewStatusLabels';
import {
  SlideContainer,
  SlideAction,
  SlideImageWrapper,
  SlideImage,
  SlideHeader,
  SlideDescription,
} from './atoms';
import { copySlideToPresentationModal } from './copySlideToPresentationModal';
import { SlideHandler } from './types';
import { getSlideWorkspacesMetadata } from 'presentationEditor/presentationUtil';
import { info, ModalSize } from '@ardoq/modal';
import ConnectedWorkspacesDialog from './ConnectedWorkspacesDialog';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { RichTextReader } from '@ardoq/rich-text-editor';
import { Header3 } from '@ardoq/typography';

type WorkspaceInfoIconProperties = {
  slide: VisualizationSlide;
  hasFullAccess: boolean;
};

const onConnectedWorkspacesClick = async (
  slide: VisualizationSlide,
  hasFullAccess: boolean
) => {
  const slideTitle = slide.name || 'slide';
  const props = {
    ...getSlideWorkspacesMetadata(slide),
    partialViewWarning: !hasFullAccess,
  };
  info({
    title: `Connected workspaces to ${slideTitle}`,
    text: <ConnectedWorkspacesDialog {...props} />,
    modalSize: ModalSize.S,
  });
};

const WorkspaceInfoIcon = memo(
  ({ slide, hasFullAccess }: WorkspaceInfoIconProperties) => {
    const { newWorkspacesToConsiderNames, missingReferencedWorkspaceNames } =
      getSlideWorkspacesMetadata(slide);
    const slideWarning =
      !hasFullAccess ||
      newWorkspacesToConsiderNames.length ||
      missingReferencedWorkspaceNames.length;
    const infoTooltipText = newWorkspacesToConsiderNames.length
      ? 'See newly connected workspaces'
      : !hasFullAccess
        ? "You don't have full access to all workspaces shown in this slide"
        : 'See connected workspaces';

    return (
      <SlideAction
        onClick={() => onConnectedWorkspacesClick(slide, hasFullAccess)}
        data-toggle="tooltip"
        data-placement="left"
        data-original-title={infoTooltipText}
      >
        {slideWarning ? (
          <IconBadge statusType={StatusType.WARNING} iconName={IconName.INFO} />
        ) : (
          <Icon iconName={IconName.INFO} />
        )}
      </SlideAction>
    );
  }
);

export const SlideView = ({
  dropdownIsopen,
  slide,
  canEdit,
  isPresentationPreview = false,
  viewIsSupported = false,
  viewId,
  viewStatus,
  isDiscontinued,
  imageSource,
  descriptionRef,
  hasDescriptionToggle,
  isDescriptionExpanded,
  hasFullAccess,
  presentations,
  onSlideClick,
  onEditClick,
  onReplaceSlideClick,
  onDeleteClick,
  setDescriptionExpanded,
  setDropdownIsOpen,
}: {
  dropdownIsopen: boolean;
  slide: APISlideAttributes;
  canEdit: boolean;
  viewId: ViewIds | undefined;
  viewStatus:
    | ViewIds.NONE
    | MetaInfoViewStatus
    | MetaInfoViewStatus
    | undefined;
  isDiscontinued: boolean;
  imageSource: string | undefined;
  descriptionRef: RefObject<HTMLDivElement>;
  hasDescriptionToggle: boolean;
  isDescriptionExpanded: boolean;
  isPresentationPreview?: boolean;
  viewIsSupported?: boolean;
  hasFullAccess: boolean;
  presentations: APIPresentationAssetAttributes[];
  onSlideClick: SlideHandler;
  onEditClick: SlideHandler;
  onReplaceSlideClick: SlideHandler;
  onDeleteClick: SlideHandler;
  setDescriptionExpanded: (value: boolean) => void;
  setDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isDeprecated =
    viewStatus === MetaInfoViewStatus.SOON_TO_BE_DISCONTINUED || isDiscontinued;
  const toggleDescriptionExpanded = () =>
    setDescriptionExpanded(!isDescriptionExpanded);
  return (
    <>
      <SlideContainer
        className={['clickable', dropdownIsopen && 'dropdown-open']
          .filter(Boolean)
          .join(' ')}
        onClick={() => onSlideClick(slide)}
        data-intercom-target="slide"
      >
        {canEdit && (
          <FlexBox justify="flex-end" align="baseline">
            {!isPresentationPreview &&
              (viewIsSupported ||
                (hasFeature(Features.CAN_UPDATE_DEPRECATED_SLIDES) &&
                  isVisualizationSlide(slide) &&
                  slide.view === viewInterface.getMainView()?.id)) && (
                <SlideAction
                  onClick={
                    !hasFullAccess
                      ? undefined
                      : event => {
                          event.stopPropagation();
                          onReplaceSlideClick(slide);
                        }
                  }
                  data-tooltip-text="Replace slide with current view"
                  isDisabled={!hasFullAccess}
                >
                  <Icon iconName={IconName.SYNC} />
                </SlideAction>
              )}
            <SlideAction
              onClick={event => {
                event.stopPropagation();
                onEditClick(slide);
              }}
              data-tooltip-text="Edit description & title"
            >
              <Icon iconName={IconName.EDIT} />
            </SlideAction>
            {isVisualizationSlide(slide) && (
              <WorkspaceInfoIcon slide={slide} hasFullAccess={hasFullAccess} />
            )}
            <ButtonWithDropdown
              buttonSize={ButtonSize.SMALL}
              tooltipText="More actions"
              iconName={IconName.MORE_VERT}
              onMenuClose={() => setDropdownIsOpen(false)}
              onMenuOpen={() => setDropdownIsOpen(true)}
              options={[
                {
                  label: 'Delete',
                  iconName: IconName.DELETE,
                  onClick: () => onDeleteClick(slide),
                },
                {
                  label: 'Copy to another presentation',
                  iconName: IconName.COPY,
                  onClick: () =>
                    copySlideToPresentationModal(slide, presentations),
                },
              ]}
              buttonType={ButtonType.GHOST}
            />
          </FlexBox>
        )}
        <SlideHeader>
          <FlexBox align="center" flexWrap>
            {slide.name && (
              <Box paddingY="small" paddingRight="small">
                <Header3>{slide.name}</Header3>
              </Box>
            )}
            {isDeprecated && (
              <Box marginTop="small">
                <Tag
                  statusType={
                    viewStatus ? viewStatusBrands[viewStatus] : undefined
                  }
                  label={viewStatus ? viewStatusLabels[viewStatus] : undefined}
                />
              </Box>
            )}
          </FlexBox>
        </SlideHeader>
        <FlexBox justify="space-between" align="end" gap="small">
          <FlexBox width="full">
            <SlideImageWrapper>
              {isDiscontinued ? (
                <DoqFace
                  doqSize={DoqSize.EXTRA_SMALL}
                  doqType={getDoqTypeForView(viewId)}
                />
              ) : (
                <SlideImage src={imageSource} alt="Slide image" />
              )}
            </SlideImageWrapper>
          </FlexBox>
        </FlexBox>
        <Box padding="xsmall">
          <SlideDescription
            ref={descriptionRef}
            $isExpanded={hasDescriptionToggle && isDescriptionExpanded}
          >
            <RichTextReader content={slide.description} />
          </SlideDescription>
          {hasDescriptionToggle && (
            <SmallSecondaryButton
              onClick={e => {
                e.stopPropagation();
                toggleDescriptionExpanded();
              }}
            >
              {isDescriptionExpanded ? 'Show less' : 'Show more'}
              <Icon
                iconSize={IconSize.SMALL}
                iconName={
                  isDescriptionExpanded
                    ? ChevronIconName.UP
                    : ChevronIconName.DOWN
                }
              />
            </SmallSecondaryButton>
          )}
        </Box>
      </SlideContainer>
    </>
  );
};
