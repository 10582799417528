import { useEffect, useState } from 'react';
import Workspaces from 'collections/workspaces';
import searchService from 'search/searchAPIService';
import {
  SearchFieldNames,
  composeSearchQueryFromSubQuery,
} from 'search/AdvancedSearch/utils';
import { SecondaryButton } from '@ardoq/button';
import {
  SearchContext,
  composeAdvancedSearchFilterGroups,
} from '@ardoq/query-builder';
import { QueryBuilderWithSuggestionsLoaders } from 'search/QueryBuilderWithSuggestionsLoaders';
import { Select, SelectOption } from '@ardoq/select';
import PreviewBlock from './PreviewBlock';
import { FieldsWrapper, LabelHelpIconWithPopover } from '@ardoq/forms';
import { MetamodelPreview } from '@ardoq/metamodel-visualization';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  BooleanOperator,
  PermissionAccessLevel,
  QueryBuilderSubquery,
  SearchResultResponse,
} from '@ardoq/api-types';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { uniq } from 'lodash';
import { getParentsByWorkspaceId } from 'utils/hierarchy';
import { connect } from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import assetFolders$, {
  AssetFoldersState,
} from 'streams/assetFolders/assetFolders$';
import workspaces$, { WorkspacesState } from 'streams/workspaces/workspaces$';
import { map } from 'rxjs/operators';
import { LabeledValue, Workspace } from 'aqTypes';
import { logError } from '@ardoq/logging';
import {
  ProtectedSelectionPlaceholder,
  getMaybeProtectSelectedOption,
} from './maybeProtectSelectedOption';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { fieldInterface } from '@ardoq/field-interface';
import { canAccessWorkspace } from './surveyUtil';
import { SurveyBuilderLocation } from './types';
import { trackEvent } from 'tracking/tracking';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';
import { Space } from '@ardoq/style-helpers';
import { getComponentTypeFields } from './questions/utils';
import { getSearchContextRule } from '@ardoq/query-builder';
import { Link } from '@ardoq/typography';
import { ScenarioModeState } from 'scope/types';

const { ROOT_WORKSPACE, TYPE_NAME } = SearchFieldNames;

type ComponentSubSelectorProperties = {
  componentTypeName?: string | null;
  workspace?: string | null;
  workspaceOptions: LabeledValue<ArdoqId>[];
  showMetamodelPreview?: boolean;
  surveyWorkspaceId?: ArdoqId | null;
  workspaceDoesNotExistErrorMessage?: string;
  workspaceAccessErrorMessage?: string;
  workspaceAccessWarningMessage?: string;
  compTypeMissingErrorMessage?: string;
  advancedSearchRules?: QueryBuilderSubquery;
  advancedSearchCondition?: string;
  validateInputs?: boolean;
  // The state of the toggle.
  showAdvancedOptions?: boolean;
  // Flag to show or hide the toggle itself.
  showAdvancedSearchToggle?: boolean;
  showAdditionalIdentifiers?: boolean;
  additionalIdentifier?: string;
  description?: string;
  accessLevel: PermissionAccessLevel;
  workspaceSelectHint?: string;
  surveyBuilderLocation: SurveyBuilderLocation;
  permissionsContext: PermissionContext;
  hideWorkspaceSelector?: boolean;
  hideComponentTypeSelector?: boolean;
  identifyingFieldError?: string;
  setAdditionalIdentifier?: (identifier: string | null) => void;
  updateComponentTypeName: (value: string) => void;
  updateWorkspace: (value: string) => void;
  toggleAdvancedOptions?: () => void;
  updateParentState?: (args: any) => void;
  componentTypeHint?: string;
  onWorkspaceSelectBlur?: () => void;
  onComponentTypeSelectBlur?: () => void;
  workspaceRequiredErrorMessage?: string;
  componentTypeRequiredErrorMessage?: string;
  workspaceWarningMessage?: string;
  componentTypeWarningMessage?: string;
};

const toWorkspaceOptions = ([assetFolders, workspaces, permissionsContext]: [
  AssetFoldersState,
  WorkspacesState,
  PermissionContext,
]) => {
  const parentsByWorkspaceId = getParentsByWorkspaceId(
    Object.values(workspaces.byId),
    assetFolders
  );
  return {
    workspaceOptions: Object.entries(workspaces.byId).map(([id, { name }]) => ({
      value: id,
      label: name,
      breadcrumbs: parentsByWorkspaceId.get(id)?.map(text => ({ text })),
    })),
    permissionsContext,
  };
};

const workspaceAccessLevelCheckFunctionsMap: Record<
  PermissionAccessLevel,
  (
    permissionsContext: PermissionContext,
    workspaceId: ArdoqId,
    activeScenarioState: ScenarioModeState | null
  ) => boolean
> = {
  [PermissionAccessLevel.ADMIN]:
    workspaceAccessControlInterface.canAdminWorkspace,
  [PermissionAccessLevel.EDIT]:
    workspaceAccessControlInterface.canEditWorkspace,
  [PermissionAccessLevel.READ]:
    workspaceAccessControlInterface.canAccessWorkspace,
};

const getFilterWorkspaceOptionsByAccessLevel = (
  accessLevel: PermissionAccessLevel,
  permissionsContext: PermissionContext
) => {
  const accessLevelCheck = workspaceAccessLevelCheckFunctionsMap[accessLevel];
  return ({ value: workspaceId }: LabeledValue<ArdoqId>): boolean =>
    accessLevelCheck(permissionsContext, workspaceId, null);
};

const getFieldOptions = (
  workspace: Workspace,
  componentTypeName?: string | null
) => {
  const fields = componentTypeName
    ? getComponentTypeFields(workspace, componentTypeName)
    : fieldInterface.getAllFieldsOfWorkspace(workspace.id);

  return composeAdvancedSearchFilterGroups(SearchContext.COMPONENT, fields);
};

const UNSUPPORTED_IDENTIFIER_FIELD_TYPES = [
  APIFieldType.USER,
  APIFieldType.DATE_ONLY_RANGE,
  APIFieldType.DATE_TIME_RANGE,
  APIFieldType.TEXT_AREA,
  APIFieldType.SELECT_MULTIPLE_LIST,
];

const fieldsToOptions = (fields: APIFieldAttributes[] = []) => {
  return fields.map(field => {
    return {
      label: field.label,
      value: field.name,
    };
  });
};

const ComponentSubsetSelector = ({
  workspace,
  surveyWorkspaceId,
  componentTypeName,
  showMetamodelPreview = true,
  showAdvancedOptions,
  advancedSearchRules,
  workspaceDoesNotExistErrorMessage,
  workspaceAccessErrorMessage,
  workspaceAccessWarningMessage,
  compTypeMissingErrorMessage,
  description = '',
  validateInputs = true,
  showAdvancedSearchToggle = true,
  showAdditionalIdentifiers,
  additionalIdentifier,
  workspaceOptions,
  accessLevel,
  workspaceSelectHint,
  surveyBuilderLocation,
  permissionsContext,
  hideWorkspaceSelector = false,
  hideComponentTypeSelector = false,
  identifyingFieldError,
  setAdditionalIdentifier,
  updateWorkspace,
  toggleAdvancedOptions,
  updateComponentTypeName,
  updateParentState = () => null,
  componentTypeHint,
  onWorkspaceSelectBlur,
  onComponentTypeSelectBlur,
  workspaceRequiredErrorMessage,
  componentTypeRequiredErrorMessage,
  workspaceWarningMessage,
  componentTypeWarningMessage,
}: ComponentSubSelectorProperties) => {
  const [searchResults, setSearchResults] = useState<
    SearchResultResponse[] | undefined
  >(undefined);
  const [searchFailed, setSearchFailed] = useState(false);
  const [accessibleWorkspaceOptions, setAccessibleWorkspaceOptions] = useState<
    LabeledValue<string>[]
  >([]);

  useEffect(() => {
    const filterWorkspaceOptionsByAccessLevel =
      getFilterWorkspaceOptionsByAccessLevel(accessLevel, permissionsContext);
    const _accessibleWorkspaceOptions = workspaceOptions.filter(
      filterWorkspaceOptionsByAccessLevel
    );
    setAccessibleWorkspaceOptions(_accessibleWorkspaceOptions);
  }, [permissionsContext, workspaceOptions, accessLevel]);
  const previewSearch = () => {
    searchService
      .componentSearch(
        composeSearchQueryFromSubQuery({
          attributes: [
            {
              attribute: ROOT_WORKSPACE,
              value: workspace,
            },
            componentTypeName
              ? {
                  attribute: TYPE_NAME,
                  value: componentTypeName,
                }
              : null,
          ].filter(ExcludeFalsy),
          additionalConditions: showAdvancedOptions
            ? advancedSearchRules
            : undefined,
        })
      )
      .then(result => {
        if (isArdoqError(result)) {
          setSearchResults(undefined);
          setSearchFailed(true);
          return;
        }
        const { results } = result;
        setSearchResults(results);
        setSearchFailed(false);
      });
  };

  useEffect(() => {
    setSearchResults(undefined);
  }, [workspace, componentTypeName]);

  let compTypeOptions: SelectOption<string>[] = [];
  const selectedWorkspace = workspace
    ? Workspaces.collection.get(workspace)
    : undefined;

  if (selectedWorkspace) {
    const selectedModel = selectedWorkspace.getModel();
    const modelTypes = selectedModel?.getAllTypes();
    compTypeOptions = modelTypes
      ? Object.keys(modelTypes).map(typeId => ({
          value: modelTypes[typeId].name,
          label: modelTypes[typeId].name,
        }))
      : [];
  }

  const componentTypeFields =
    selectedWorkspace && componentTypeName
      ? getComponentTypeFields(selectedWorkspace, componentTypeName)
      : undefined;

  const maybeProtectSelectedOption = getMaybeProtectSelectedOption(workspace);

  const componentTypeErrorMessage =
    validateInputs && !componentTypeName
      ? componentTypeRequiredErrorMessage
      : compTypeMissingErrorMessage;

  const hasAccessToWorkspace = canAccessWorkspace(
    workspace,
    workspaceDoesNotExistErrorMessage,
    workspaceAccessErrorMessage || workspaceAccessWarningMessage
  );
  return (
    <>
      {!hideWorkspaceSelector && (
        <FieldsWrapper>
          <Select
            onBlur={onWorkspaceSelectBlur}
            label="Select workspace"
            hintMessage={workspaceSelectHint}
            options={accessibleWorkspaceOptions}
            isClearable={false}
            value={maybeProtectSelectedOption(
              workspaceOptions,
              workspace,
              ProtectedSelectionPlaceholder.HIDDEN_WORKSPACE
            )}
            onValueChange={value => updateWorkspace(value as string)}
            errorMessage={
              validateInputs
                ? !workspace
                  ? workspaceRequiredErrorMessage
                  : workspaceAccessErrorMessage ||
                    workspaceDoesNotExistErrorMessage
                : undefined
            }
            dataTestId="workspace-select"
            popoverHelpContent={description}
            warningMessage={
              workspaceWarningMessage || workspaceAccessWarningMessage
            }
          />
          {hasAccessToWorkspace && (
            <Link
              target="_blank"
              href={`/app/view/pagesView/workspace/${workspace}`}
            >
              Go to workspace
            </Link>
          )}
        </FieldsWrapper>
      )}
      {workspace && !hideComponentTypeSelector && (
        <FieldsWrapper>
          <Select
            label="Select component type"
            hintMessage={componentTypeHint}
            onBlur={onComponentTypeSelectBlur}
            options={compTypeOptions}
            isClearable={false}
            value={maybeProtectSelectedOption(
              compTypeOptions,
              componentTypeName,
              ProtectedSelectionPlaceholder.HIDDEN_COMPONENT
            )}
            onValueChange={value => updateComponentTypeName(value as string)}
            errorMessage={
              workspaceAccessErrorMessage || componentTypeErrorMessage
            }
            warningMessage={
              workspaceAccessWarningMessage || componentTypeWarningMessage
            }
            dataTestId="component-type-select"
          />
          {showMetamodelPreview && selectedWorkspace && (
            <MetamodelPreview
              workspaceIds={uniq([
                selectedWorkspace.id,
                surveyWorkspaceId,
              ]).filter(ExcludeFalsy)}
              clickId="viewpoint-builder-metamodel-preview"
              logError={logError}
            />
          )}
        </FieldsWrapper>
      )}

      {showAdvancedSearchToggle && advancedSearchRules && selectedWorkspace && (
        <>
          <FieldsWrapper>
            <Space $align="center" $gap="s4">
              <SecondaryButton
                onClick={() => {
                  toggleAdvancedOptions?.();
                  trackEvent('Survey builder: toggle advanced search', {
                    from: surveyBuilderLocation,
                    toggle: !showAdvancedOptions,
                  });
                }}
                dataTestId="advanced-search-button"
              >
                {showAdvancedOptions
                  ? 'Clear advanced search'
                  : 'Use advanced search'}
              </SecondaryButton>
              <LabelHelpIconWithPopover
                content={`Use the advanced search to filter the component selection
                    with fine-grained control.`}
              />
            </Space>
          </FieldsWrapper>
          <div data-test-id="survey-query-builder">
            {showAdvancedOptions && (
              <QueryBuilderWithSuggestionsLoaders
                includeContextSelect
                contextSelectIsReadOnly
                defaultSearchContext={SearchContext.COMPONENT}
                maxSubqueryNestingLevel={6}
                query={{
                  condition: BooleanOperator.AND,
                  rules: [
                    getSearchContextRule(SearchContext.COMPONENT),
                    advancedSearchRules,
                  ],
                }}
                getFilterGroups={() => {
                  return getFieldOptions(selectedWorkspace, componentTypeName);
                }}
                onQueryChange={newQuery => {
                  const subquery = newQuery.rules[1];
                  const { condition } = subquery;
                  const { rules } = subquery;
                  updateParentState({
                    advancedSearchCondition: condition,
                  });
                  updateParentState({
                    advancedSearchRules: { condition, rules },
                  });
                  setSearchFailed(false);
                  setSearchResults(undefined);
                }}
              />
            )}
          </div>
        </>
      )}
      {showAdditionalIdentifiers && selectedWorkspace && componentTypeName && (
        <FieldsWrapper>
          <Select
            label="Identifying field"
            popoverHelpContent={
              <>
                <p>
                  Give the component name more context by adding an identifying
                  field.
                </p>
                <p>
                  For example, adding the identifying field &quot;Job
                  Title&quot; to a person component might look like this:
                </p>
                <img
                  alt="image showing identifying field example"
                  src="/img/surveys/survey_identifying_field.png"
                />
              </>
            }
            helperText="The field will be visible to all contributors who have access to the survey."
            dataTestId="identifier-field-option"
            options={fieldsToOptions(
              componentTypeFields?.filter(
                field =>
                  !UNSUPPORTED_IDENTIFIER_FIELD_TYPES.includes(field.type)
              )
            )}
            placeholder="None"
            value={additionalIdentifier}
            onValueChange={identifier => setAdditionalIdentifier?.(identifier)}
            isClearable={true}
            errorMessage={identifyingFieldError}
          />
        </FieldsWrapper>
      )}
      {workspace && (
        <FieldsWrapper>
          <div>
            <SecondaryButton
              onClick={() => {
                if (searchResults) {
                  setSearchResults(undefined);
                  trackEvent(
                    'Survey builder: hide preview selected components'
                  );
                } else {
                  previewSearch();
                  trackEvent('Survey builder: preview selected components');
                }
              }}
              dataTestId="component-preview-button"
            >
              {searchResults ? 'Hide preview' : 'Preview selected components'}
            </SecondaryButton>
          </div>
          <PreviewBlock
            searchResults={searchResults}
            searchFailed={searchFailed}
            additionalIdentifyingField={componentTypeFields?.find(
              field => field.name === additionalIdentifier
            )}
          />
        </FieldsWrapper>
      )}
    </>
  );
};

export default connect(
  ComponentSubsetSelector,
  combineLatest([
    assetFolders$,
    workspaces$,
    currentUserPermissionContext$,
  ]).pipe(map(toWorkspaceOptions))
);
