import { useEffect } from 'react';
import { Button, ButtonType } from '@ardoq/button';
import { createEmptyStep } from 'viewpoints/utils';
import { AvailableTriples } from '../../types';
import { validatePartialSteps } from '../../validation';
import { dispatchAction } from '@ardoq/rxbeach';
import { fetchAvailableTriples } from 'viewpoints/actions';
import { Icon, IconName } from '@ardoq/icons';
import { arrayMove, DragAndDropSectionsProvider } from '@ardoq/drag-and-drop';
import Step from './Step';
import { useController, useFormContext } from 'react-hook-form';
import { APIDiscoverViewpointAttributes } from '@ardoq/api-types';
import { Stack, FlexBox } from '@ardoq/layout';

type DynamicGroupedTraversalsFormProps = {
  availableTriples: AvailableTriples;
  isDisabled: boolean;
  hasNoSubmittableChanges: boolean;
};

const DynamicGroupedTraversalsForm = ({
  availableTriples,
  isDisabled,
  hasNoSubmittableChanges,
}: DynamicGroupedTraversalsFormProps) => {
  const {
    field: { onChange },
  } = useController({
    name: 'dynamicGroupedTraversals',
  });
  const { watch } = useFormContext<APIDiscoverViewpointAttributes>();
  const [workspaceIds, steps] = watch([
    'workspaceIds',
    'dynamicGroupedTraversals',
  ]);

  useEffect(() => {
    if (!workspaceIds.length) return;
    dispatchAction(fetchAvailableTriples(workspaceIds));
  }, [workspaceIds]);

  const validationMap = validatePartialSteps(
    steps,
    availableTriples,
    hasNoSubmittableChanges
  );

  const validate = (stepIndex: number) =>
    validationMap.stepValidationMap.get([stepIndex].join());

  const handleConstraintValidate =
    (stepIndex: number) => (constraintIndex: number) =>
      validationMap.constraintValidationMap.get(
        [stepIndex, constraintIndex].join()
      );

  const updateOrder = (newIndex: number, oldIndex: number) => {
    if (newIndex !== -1 && newIndex < steps.length) {
      const updatedSteps = arrayMove(steps, oldIndex, newIndex);
      onChange(updatedSteps);
    }
  };

  return (
    <Stack align="flex-start" gap="large">
      <FlexBox flexDirection="column" gap="medium" width="full">
        <DragAndDropSectionsProvider>
          {steps.map((step, index) => {
            const validation = validate(index);
            return (
              <Step
                key={index}
                index={index}
                steps={steps}
                validation={validation}
                step={step}
                availableTriples={availableTriples}
                validationMap={validationMap}
                setValue={onChange}
                onOrderChange={updateOrder}
                dragAndDropCardId={step.id}
                handleConstraintValidate={handleConstraintValidate}
                isDisabled={isDisabled}
              />
            );
          })}
        </DragAndDropSectionsProvider>
      </FlexBox>
      <Button
        buttonType={ButtonType.SECONDARY}
        onClick={() => {
          const updatedSteps = [
            ...steps,
            createEmptyStep(steps[steps.length - 1], availableTriples.data),
          ];
          onChange(updatedSteps);
        }}
        dataTestId="add-step-button"
        isDisabled={isDisabled}
      >
        <Icon iconName={IconName.ADD} />
        Add step
      </Button>
    </Stack>
  );
};

export default DynamicGroupedTraversalsForm;
