import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import { map } from 'rxjs/operators';
import currentUser$ from 'streams/currentUser/currentUser$';
import workspaces$ from 'streams/workspaces/workspaces$';
import {
  reportBuilderOperations,
  ReportBuilderSectionErrorProps,
} from '@ardoq/report-builder';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { combineLatest } from 'rxjs';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { Features, hasFeature } from '@ardoq/features';
import { locale$ } from 'streams/locale$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import resourcePermissions$ from '../../resourcePermissions/resourcePermissions$';
import { dispatchAction } from '@ardoq/rxbeach';
import reportBuilderForm$ from './reportBuilderForm$';
import { getReportBuilderCommands } from './commands/reportBuilderCommands';
import { reportAccessControlInterface } from 'resourcePermissions/accessControlHelpers/report';
import { withOnFirstSubscription } from '../../streams/utils/streamUtils';
import { getZonePermissions } from '../../subdivisionEditor/Steps/permissionsConfiguration/zones/permissions$/actions';
import { fetchPermissionGroups } from '../../admin/accessControl/PermissionGroups/streams/actions';
import { getNavBarProps } from './props/navBarProps';
import { getDataSelectionSectionProps } from './props/dataSelectionSectionProps';
import { getQuerySectionProps } from './props/querySectionProps';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import reportNavigation$ from '../navigation/reportNavigation$';
import advancedSearchCustomFields$ from './advancedSearchCustomFields$';
import { getReportError } from './reportError';
import { warnBeforeNavigate } from 'router/history';
import { getUserAndGroupOptions } from 'resourcePermissions/utils';
import { ReportBuilderContainerProps } from './ReportBuilderContainer';
import { getNavBarCommands } from './commands/navBarCommands';
import { getNotificationsProps } from './notificationProps';
import { getDataPresentationSectionProps } from './props/dataPresentationSectionProps';
import { keyBy } from 'lodash';

const reportBuilderViewModel$ = withOnFirstSubscription(
  combineLatest([
    locale$,
    workspaces$,
    permissionGroup$,
    currentUser$,
    orgUsers$,
    currentUserPermissionContext$,
    reportBuilderForm$,
    subdivisions$,
    resourcePermissions$,
    reportNavigation$,
    advancedSearchCustomFields$,
  ]).pipe(
    map(
      ([
        currentUserLocale,
        workspaces,
        { groupsById: byGroupId },
        currentUser,
        { byId: byUserId },
        permissionContext,
        {
          reportTemplate,
          persistedReport,
          isReportBuilderLoading,
          isSubmitting,
          sectionsWithErrors,
          showErrorsOnInputs,
          errorFromSave,
          showErrorsForInvalidQuery,
          isValidAdvancedSearch,
          isSearchBeingExecuted,
          searchResults,
          errorFromSearch,
          isSearchAggregatesBeingExecuted,
          searchAggregatesResults,
          errorFromSearchAggregates,
          reportDataUsedForSearch,
          isFetchingCustomFields,
          selectedWorkspacesWithNoAccess,
          hasWorkspacesWithNoAccess,
        },
        subdivisionsContext,
        { apiState },
        { reportId },
        { customFields, customFieldsError },
      ]): ReportBuilderContainerProps | null => {
        const hasSubdivisionsFeature = hasFeature(Features.PERMISSION_ZONES);
        const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);

        const hasUnsavedProgress = reportBuilderOperations.hasUnsavedProgress(
          reportTemplate,
          persistedReport,
          hasSubdivisionsFeature
        );

        const userHasReportAdminAccess =
          !reportId ||
          reportAccessControlInterface.canAdminReport({
            reportId,
            permissionContext,
          });

        const navBarCommands = getNavBarCommands({
          reportTemplate,
          persistedReport,
          sectionsWithErrors,
          hasWorkspacesWithNoAccess,
          hasSubdivisionsFeature,
          hasUnsavedProgress,
        });

        const navBarProps = getNavBarProps({
          hasUnsavedProgress,
          isSubmitting,
          hasSubdivisionsFeature,
          permissionsApiState: apiState,
          userHasReportAdminAccess,
          commands: navBarCommands,
          reportName: persistedReport?.name || reportTemplate.name,
        });

        const reportModuleContainerProps = {
          navBarCommands,
          navBarProps,
          hasNewJourneyFeature,
        };

        if (
          isReportBuilderLoading ||
          (hasSubdivisionsFeature &&
            subdivisionsContext.zonesContext.isFetching)
        )
          return {
            isReportBuilderLoading: true,
            ...reportModuleContainerProps,
          };

        if (currentUser?.organization === null) return null; // this was added because of a possible edge case where the user could be authenticated but not have an organization.
        // https://github.com/ardoq/ardoq-front/pull/11064

        const canCreateGraphFilter =
          graphSearchAccessControlHelpers.canCreateGraphFilter(
            permissionContext
          );
        const reportError = getReportError({
          reportTemplate,
          currentUser,
          canCreateGraphFilter,
          permissionContext,
          reportId,
        });
        if (reportError) return { reportError, ...reportModuleContainerProps };

        const permissionGroups = Object.values(byGroupId);

        const reportBuilderSectionErrorProps: ReportBuilderSectionErrorProps = {
          showErrorsOnInputs,
          showErrorsForInvalidQuery,
        };
        const notificationsProps = getNotificationsProps({
          hasSubdivisionsFeature,
        });

        const attachments = keyBy(
          searchResults?.scopeData?.attachments ?? [],
          '_id'
        );

        const dataSelectionSectionProps = getDataSelectionSectionProps({
          reportTemplate,
          persistedReport,
          workspacesById: workspaces.byId,
          subdivisionsContext,
          hasSubdivisionsFeature,
          permissionContext,
          isNewFeatureNotificationVisible:
            notificationsProps.dataSelectionNotification
              .isNewFeatureNotificationVisible,
          selectedWorkspacesWithNoAccess,
          hasWorkspacesWithNoAccess,
          ...reportBuilderSectionErrorProps,
        });

        const querySectionProps = getQuerySectionProps({
          reportTemplate,
          errorFromSearch,
          searchResults,
          isSearchBeingExecuted,
          isValidAdvancedSearch,
          reportDataUsedForSearch,
          canCreateGraphFilter,
          hasSubdivisionsFeature,
          customFields,
          customFieldsError,
          isFetchingCustomFields,
          hasWorkspacesWithNoAccess,
          ...reportBuilderSectionErrorProps,
        });

        const dataPresentationSectionProps = getDataPresentationSectionProps({
          errorFromSearchAggregates,
          reportTemplate,
          searchResults,
          searchAggregatesResults,
          users: byUserId,
          attachments,
          currentUserLocale,
          isColumnRenameNotificationVisible:
            notificationsProps.columnRenameNotification
              .isNewFeatureNotificationVisible,
          isSearchBeingExecuted,
          isSearchAggregatesBeingExecuted,
          ...reportBuilderSectionErrorProps,
        });

        const commands = getReportBuilderCommands({
          reportTemplate,
          userHasReportAdminAccess,
          currentUser,
          searchAggregatesResults,
          searchResults,
          isValidAdvancedSearch,
          currentUserLocale,
          users: byUserId,
          attachments,
          subdivisionsContext,
          selectedWorkspacesWithNoAccess,
        });

        return {
          isReportBuilderLoading: false,
          permissionGroups,
          currentUserLocale,
          report: reportTemplate,
          commands,
          hasSubdivisionsFeature,
          hasUnsavedProgress,
          dataSelectionSectionProps,
          errorFromSave,
          querySectionProps,
          userHasReportAdminAccess,
          notificationsProps,
          dataPresentationSectionProps,
          warnBeforeNavigate: warnBeforeNavigate,
          getUserAndGroupOptions: getUserAndGroupOptions,
          ...reportModuleContainerProps,
          ...reportBuilderSectionErrorProps,
        };
      }
    ),
    excludeNullAndUndefined()
  ),
  () => {
    dispatchAction(getZonePermissions());
    dispatchAction(fetchPermissionGroups());
  }
);

export default reportBuilderViewModel$;
