import styled from 'styled-components';
import { SecondaryButton } from '@ardoq/button';
import { s32 } from '@ardoq/design-tokens';

const Wrapper = styled.div`
  height: 0;
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  position: relative;
  bottom: 52px;
  right: ${s32};
`;

interface AdvancedQueryControlsProps {
  onClick: () => void;
}

const AdvancedQueryControls = ({ onClick }: AdvancedQueryControlsProps) => {
  return (
    <Wrapper>
      <ButtonWrapper>
        <SecondaryButton onClick={onClick}>Hide</SecondaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default AdvancedQueryControls;
