import styled from 'styled-components';
import { SidebarMenuSection } from '@ardoq/sidebar-menu';
import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { Link, caption } from '@ardoq/typography';
import { SecondaryButton, GhostButton } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import { recalculateCalculatedField } from 'search/CalculatedSearch/actions';
import { colors, s16 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { Field } from '@ardoq/forms';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { CalculatedFieldEventStatus, CalculatedFieldEventMap } from './types';
import { ErrorNotification } from '@ardoq/status-ui';
import { getCalculatedFieldErrorEvents } from './utils';
import { Icon, IconName, IconSize, SpinnerIcon } from '@ardoq/icons';
import { formatDateTime } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';

const CalculatedField = styled(Space).attrs({ $gap: 's8', $isVertical: true })`
  padding: 0 ${s16} ${s16} ${s16};
  border-bottom: 1px solid ${colors.grey80};
`;

const ErrorNotificationContainer = styled.div`
  padding: 0 ${s16};
`;

const LastCalculation = styled.div`
  ${caption};
  color: ${colors.grey50};
`;

const InfoMessageContainer = styled.div`
  max-width: 304px;
`;

const RecalculatedTag = styled(Space)`
  color: ${colors.green15};
  ${caption};
`;

const getInfoMessage = () => (
  <InfoMessageContainer>
    <p>
      Calculated fields automatically process information across your workspaces
      and stores the result as a field value. All calculations are scheduled to
      run hourly.
    </p>

    <p>
      Read more about this on our{' '}
      <Link typography="text2" href={KnowledgeBaseLink.CALCULATED_FIELDS}>
        Knowledge Base
      </Link>
    </p>
  </InfoMessageContainer>
);

interface CalculatedFieldSectionProps {
  calculatedFields: APIFieldAttributes[];
  fieldStatusMap: CalculatedFieldEventMap;
  branchId?: ArdoqId | null;
}

export const CalculatedFieldSection = ({
  calculatedFields,
  fieldStatusMap,
  branchId,
}: CalculatedFieldSectionProps) => {
  const locale = getCurrentLocale();
  const errorEvents = getCalculatedFieldErrorEvents(fieldStatusMap);

  return (
    <SidebarMenuSection
      title="Calculated fields"
      isCollapsible={false}
      infoPopover={getInfoMessage}
    >
      <>
        {Object.keys(errorEvents).length > 1 ? (
          <ErrorNotificationContainer>
            <ErrorNotification>
              {Object.keys(errorEvents).length} fields failed to recalculate.
              See the errors of the field level to resolve them.
            </ErrorNotification>
          </ErrorNotificationContainer>
        ) : null}
        {calculatedFields.map(({ _id, label, calculatedFieldExtras }) => (
          <CalculatedField key={_id}>
            <Field label={label}>
              {fieldStatusMap[_id]?.status ===
              CalculatedFieldEventStatus.CALCULATING ? (
                <GhostButton isActive>
                  Calculating <SpinnerIcon />
                </GhostButton>
              ) : (
                <SecondaryButton
                  onClick={() => {
                    dispatchAction(
                      recalculateCalculatedField({
                        fieldId: _id,
                        branchId,
                      })
                    );
                  }}
                >
                  Recalculate
                </SecondaryButton>
              )}
            </Field>

            <Space $justify="space-between">
              <LastCalculation>{`Last calculation: ${
                calculatedFieldExtras?.lastRecalculation
                  ? formatDateTime(
                      calculatedFieldExtras?.lastRecalculation,
                      locale
                    )
                  : 'Never'
              }`}</LastCalculation>

              {fieldStatusMap[_id]?.status ===
                CalculatedFieldEventStatus.DONE ||
              fieldStatusMap[_id]?.status ===
                CalculatedFieldEventStatus.DONE_NOTIFIED ? (
                <RecalculatedTag
                  $justify="space-between"
                  $gap="s8"
                  $align="center"
                >
                  Recalculated
                  <Icon
                    iconName={IconName.CHECK_CIRCLE}
                    iconSize={IconSize.SMALL}
                    style={{ color: colors.green50 }}
                  />
                </RecalculatedTag>
              ) : null}
            </Space>

            {errorEvents[_id] ? (
              <Space $align="center" style={{ color: colors.red50 }} $gap="s8">
                <Icon iconName={IconName.ERROR} iconSize={IconSize.SMALL} />
                {errorEvents[_id].status.message}
              </Space>
            ) : null}
          </CalculatedField>
        ))}
      </>
    </SidebarMenuSection>
  );
};
