import { ButtonType } from '@ardoq/button';
import SortHeader, { SortHeaderWithOffset } from '../EntityBrowser/SortHeader';
import { NameCellWrapper, TextEllipsisWrapper } from '../EntityBrowser/atoms';
import { HeaderCell, RowType } from '@ardoq/table';
import { formatDateTime } from '@ardoq/date-time';
import {
  ColumnsOptions,
  Folder,
  Row,
  RowItem,
  SortColumnOptions,
} from '../EntityBrowser/types';
import { AssetRow } from './types';
import SelectAllCheckbox from '../EntityBrowser/SelectAllCheckbox';
import {
  extractAssetsIdsList,
  findIsSelected,
  getSelectableIdsFromDataSource,
} from '../EntityBrowser/utils';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import EmptyFolderRow from '../EntityBrowser/EmptyFolderRow';
import SelectCell from '../EntityBrowser/SelectCell';
import RowIcon from '../EntityBrowser/RowIcon';
import NameCell from '../EntityBrowser/NameCell';
import { AssetBrowserStateProps, AssetsBrowserProps } from './AssetsBrowser';
import FavoriteButton from '../../appContainer/DashboardContainer/FavoriteButton';
import { getCurrentLocale } from '@ardoq/locale';
import { FlexBox } from '@ardoq/layout';
import { ButtonSize } from '@ardoq/button';
import SurveyRow from 'components/EntityBrowser/SurveyRow';

export type ColumnsProps = AssetBrowserStateProps &
  Pick<
    AssetsBrowserProps,
    | 'renameConfirm'
    | 'renameCancel'
    | 'renameId'
    | 'selected'
    | 'setSelected'
    | 'setExpandedFoldersIds'
    | 'dataSource'
    | 'folderIds'
    | 'expandedFoldersIds'
  > &
  SortColumnOptions & {
    selectRows: (row: RowItem | Folder<RowItem>, rangeSelect?: boolean) => void;
  };

export const getNameColumnIncludingSelectAllCheckbox = ({
  selected,
  setSelected,
  setExpandedFoldersIds,
  sortByField,
  sortOrder,
  setSortByField,
  selectRows,
  expandedFoldersIds,
  dataSource: filteredDataSource,
  folderIds = [],
  renameId,
  renameConfirm,
  renameCancel,
  onRowOpen,
  onRowPreviewClick,
  onOpenInViewpointModeClick,
  permissionContext,
}: ColumnsProps & ColumnsOptions<AssetRow>) => {
  const hasFolders = folderIds.length !== 0;
  return {
    title: 'Name',
    dataIndex: 'name',
    headerRender: ({ title, dataIndex = 'name' }: HeaderCell) => (
      <FlexBox align={'center'} data-testid={'asset-browser-name-header'}>
        <SelectAllCheckbox
          selected={selected}
          setSelected={setSelected}
          selectableIds={getSelectableIdsFromDataSource(filteredDataSource)}
        />
        {hasFolders && setExpandedFoldersIds && (
          <ButtonWithDropdown
            buttonSize={ButtonSize.SMALL}
            options={[
              {
                label: 'Expand all folders',
                onClick: () => setExpandedFoldersIds(folderIds),
              },
              {
                label: 'Close all folders',
                onClick: () => setExpandedFoldersIds([]),
              },
            ]}
            buttonType={ButtonType.GHOST}
          />
        )}
        <SortHeaderWithOffset
          dataIndex={dataIndex}
          title={title}
          dataClickId="sort-assets-by-name"
          sortByField={sortByField}
          sortOrder={sortOrder}
          onSortChanged={setSortByField}
          $offsetMargin={hasFolders && setExpandedFoldersIds ? 10 : 36}
        />
      </FlexBox>
    ),
    valueRender: (name: string, row: Row<AssetRow>) => {
      if (row.rowType === RowType.EMPTY_FOLDER) {
        return <EmptyFolderRow row={row} />;
      }

      const noAssetIds = extractAssetsIdsList([row]).length === 0;

      if (row.rowType === RowType.SURVEY) {
        return (
          <SurveyRow
            row={row}
            name={name}
            noAssetIds={noAssetIds}
            permissionContext={permissionContext}
            selected={selected}
            selectRows={selectRows}
            onRowOpen={onRowOpen}
            onRowPreviewClick={onRowPreviewClick}
            renameCancel={renameCancel}
            renameConfirm={renameConfirm}
            renameId={renameId}
          />
        );
      }

      return (
        <NameCellWrapper>
          <SelectCell
            disabled={noAssetIds}
            isSelected={findIsSelected(row, selected)}
            indentationLevel={row.meta.level}
            rowId={row._id}
            onCheckboxClick={e => selectRows(row, e.shiftKey)}
          />
          <RowIcon
            rowType={row.rowType}
            isOpened={expandedFoldersIds?.includes(row._id)}
            onClick={() => onRowOpen(row)}
          />
          <NameCell
            rowData={row}
            isEditing={row._id !== undefined && row._id === renameId}
            onEditNameConfirm={(newName: string) =>
              renameConfirm && renameConfirm(newName, row)
            }
            onEditNameCancel={renameCancel}
            name={name}
            rowId={row._id}
            rowType={row.rowType}
            matchGroups={row.meta.matchGroups}
            onClick={() => onRowOpen(row)}
            onPreviewClick={() => onRowPreviewClick?.(row)}
            onOpenInViewpointModeClick={
              onOpenInViewpointModeClick
                ? () => onOpenInViewpointModeClick(row)
                : undefined
            }
          />
        </NameCellWrapper>
      );
    },
  };
};

export const getLastModifiedDateColumn = (props: SortColumnOptions) => {
  return {
    title: 'Last modified',
    dataIndex: 'lastUpdated',
    headerStyle: { minWidth: '150px' },
    headerRender: ({ title, dataIndex = 'lastUpdated' }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="last-modified"
        sortByField={props.sortByField}
        sortOrder={props.sortOrder}
        onSortChanged={props.setSortByField}
      />
    ),
    valueRender: (value: string) => (
      <TextEllipsisWrapper $width="150px">
        {formatDateTime(value, getCurrentLocale())}
      </TextEllipsisWrapper>
    ),
  };
};

export const getLastModifiedByColumn = (props: SortColumnOptions) => {
  return {
    headerStyle: {
      width: '200px',
    },
    title: 'Modified by',
    dataIndex: 'lastModifiedByName',
    headerRender: ({ title, dataIndex = 'lastModifiedByName' }: HeaderCell) => (
      <SortHeader
        dataIndex={dataIndex}
        title={title}
        dataClickId="last-modified-by-name"
        sortByField={props.sortByField}
        sortOrder={props.sortOrder}
        onSortChanged={props.setSortByField}
      />
    ),
    valueRender: (value: string) => (
      <TextEllipsisWrapper $width="200px">
        {value !== 'Unknown' ? value : ''}
      </TextEllipsisWrapper>
    ),
  };
};

export const getDefaultAssetsBrowserColumns =
  (columnsProps: ColumnsProps) =>
  (columnsOptions: ColumnsOptions<AssetRow>) => [
    getNameColumnIncludingSelectAllCheckbox({
      ...columnsProps,
      ...columnsOptions,
    }),
    getLastModifiedDateColumn(columnsProps),
    getLastModifiedByColumn(columnsProps),
  ];

export const getFavoritesColumn = () => ({
  headerStyle: { width: 45 },
  valueRender: (_name: any, row: Row<AssetRow>) => <FavoriteButton row={row} />,
});
