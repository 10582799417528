import { Maybe } from '@ardoq/common-helpers';
import {
  createReportUserEventTracking,
  reportBuilderOperations,
  ReportTemplate,
} from '@ardoq/report-builder';
import { Report } from '@ardoq/api-types';
import { RefObject } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  reportChangesWereDiscarded,
  reportSaveButtonWasClickedOnExistingReport,
  reportSaveButtonWasClickedOnNewReport,
  reportWasInvalidOnSaveButtonClick,
} from '../actions';
import { shouldKeepHistoricalData } from '../../utils';
import { shareReportSelected } from '../../actions';
import { trackEvent } from 'tracking/tracking';
import {
  ReportEventLocations,
  ReportTrackingEvents,
} from '@ardoq/report-reader';
import {
  openReportInReader,
  openReportOverview,
} from 'components/AppMainSidebar/utils';
import { isEmpty } from 'lodash';
import { ReportBuilderNavBarCommands } from '../NavBarButtons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { confirm } from '@ardoq/modal';

const gotToReportOverviewFromReportBuilder = () => {
  trackEvent(ReportTrackingEvents.REPORT_BUILDER_GO_TO_REPORT_OVERVIEW);
  openReportOverview();
};

const getOnViewButtonClick = (report: Maybe<Report>) => () => {
  if (report) openReportInReader({ reportId: report._id });
};

const scrollTopSectionWithErrorIntoView = (
  sectionsWithErrors: Array<RefObject<HTMLDivElement>>
) => {
  const topElement: RefObject<HTMLDivElement> = sectionsWithErrors.reduce(
    (currentTop, section) =>
      (currentTop.current?.getBoundingClientRect().top ?? -Infinity) >
      (section.current?.getBoundingClientRect().top ?? Infinity)
        ? section
        : currentTop,
    sectionsWithErrors[0]
  );
  topElement?.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  });
};

const getOnSaveButton =
  (
    reportTemplate: ReportTemplate,
    persistedReport: Maybe<Report>,
    hasWorkspacesWithNoAccess: boolean,
    sectionsWithErrors: Array<RefObject<HTMLDivElement>>,
    hasSubdivisionsFeature: boolean
  ) =>
  async () => {
    if (
      !reportBuilderOperations.isValidReport(reportTemplate) ||
      hasWorkspacesWithNoAccess
    ) {
      dispatchAction(reportWasInvalidOnSaveButtonClick());
      if (!isEmpty(sectionsWithErrors))
        scrollTopSectionWithErrorIntoView(sectionsWithErrors);
    } else if (!reportTemplate || !persistedReport) {
      dispatchAction(reportSaveButtonWasClickedOnNewReport());
    } else {
      const keepHistoricalData = await shouldKeepHistoricalData(
        persistedReport,
        reportTemplate
      );
      dispatchAction(
        reportSaveButtonWasClickedOnExistingReport({
          shouldKeepHistoricalData: keepHistoricalData,
          hasSubdivisionsFeature,
        })
      );
    }
  };

const getOnShareButtonClick = (persistedReport: Maybe<Report>) => () => {
  createReportUserEventTracking('Share', trackEvent)(
    ReportTrackingEvents.CLICKED_OPEN_SHARE_MODAL,
    {
      from: ReportEventLocations.REPORT_BUILDER,
    }
  );
  if (persistedReport) dispatchAction(shareReportSelected(persistedReport._id));
};

const getOnKBIconClick = (hasSubdivisionsFeature: boolean) => () =>
  window.open(
    hasSubdivisionsFeature
      ? KnowledgeBaseLink.HOW_TO_CREATE_A_REPORT_WITH_SUBDIVISIONS
      : KnowledgeBaseLink.HOW_TO_CREATE_A_REPORT,
    '_blank noopener'
  );

const getExitEditing =
  (persistedReport: Maybe<Report>, hasUnsavedProgress: boolean) => async () => {
    if (hasUnsavedProgress) {
      const shouldExit = await confirm({
        title: 'Discard unsaved changes',
        text: 'You have unsaved changes that will be lost. Are you sure you want to proceed?',
        confirmButtonTitle: 'Discard changes',
      });
      if (!shouldExit) return;
    }
    dispatchAction(reportChangesWereDiscarded());
    if (persistedReport) {
      openReportInReader({ reportId: persistedReport._id });
    } else gotToReportOverviewFromReportBuilder();
  };

export type GetNavBarCommandsArgs = {
  reportTemplate: ReportTemplate;
  persistedReport: Maybe<Report>;
  sectionsWithErrors: Array<RefObject<HTMLDivElement>>;
  hasWorkspacesWithNoAccess: boolean;
  hasSubdivisionsFeature: boolean;
  hasUnsavedProgress: boolean;
};

export const getNavBarCommands = ({
  reportTemplate,
  persistedReport,
  sectionsWithErrors,
  hasWorkspacesWithNoAccess,
  hasSubdivisionsFeature,
  hasUnsavedProgress,
}: GetNavBarCommandsArgs): ReportBuilderNavBarCommands => {
  return {
    saveReport: getOnSaveButton(
      reportTemplate,
      persistedReport,
      hasWorkspacesWithNoAccess,
      sectionsWithErrors,
      hasSubdivisionsFeature
    ),
    viewReport: getOnViewButtonClick(persistedReport),
    exitEditing: getExitEditing(persistedReport, hasUnsavedProgress),
    shareReport: getOnShareButtonClick(persistedReport),
    openKBArticle: getOnKBIconClick(hasSubdivisionsFeature),
  };
};
