import styled from 'styled-components';
import { ButtonWithDropdown } from '@ardoq/dropdown-menu';
import { colors, s4 } from '@ardoq/design-tokens';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { ButtonType } from '@ardoq/button';
import { APIDiscoverViewpointAttributes, ArdoqId } from '@ardoq/api-types';
import { StatusType, Tag } from '@ardoq/status-ui';
import { usePopover } from '@ardoq/popovers';

type ViewpointSelectProps = {
  currentViewpointName?: string;
  onChange: (viewpointId?: ArdoqId) => void;
  viewpoints: APIDiscoverViewpointAttributes[];
  isLoading: boolean;
  allViewpointsUnpublished: boolean;
};

const PlainTextPopover = styled.div`
  min-width: 120px;
  max-width: 240px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const VisualizationPreview = styled.img`
  border: 1px solid ${colors.grey80};
  border-radius: ${s4};
`;

export function ViewpointSelect({
  isLoading,
  onChange,
  currentViewpointName,
  viewpoints,
  allViewpointsUnpublished,
}: ViewpointSelectProps) {
  const isDisabled = allViewpointsUnpublished;
  const popoverProps = usePopover(
    _element =>
      isDisabled ? (
        <PlainTextPopover>
          There is no assigned viewpoint for this component, so the default is
          Default Overview.
          {'\n'}
          To assign a viewpoint, go to Create viewpoint and select this
          component type.
        </PlainTextPopover>
      ) : undefined,
    { followsMouse: true }
  );

  const options = viewpoints
    .filter(viewpoint => viewpoint.type === 'dynamic-grouped-traversals')
    .map(viewpoint => {
      return {
        label: (
          <div>
            {!viewpoint.published && (
              <div>
                <Tag statusType={StatusType.INFO} label="Unpublished" />
              </div>
            )}
            <span>{viewpoint.name}</span>
          </div>
        ),
        isDisabled: !viewpoint.published,
        rightContent: (
          <VisualizationPreview src={viewpoint.defaultVisualizationImageUrl} />
        ),
        description: viewpoint.description,
        truncateTitle: true,
        truncateDescription: true,
        onClick: () => {
          onChange(viewpoint._id);
        },
        testId: viewpoint.name,
      };
    });

  const defaultOption = {
    label: 'Default Overview',
    description:
      'Shows all your selected components direct neighbors. Understand its context and navigate in any direction.',
    rightContent: (
      <VisualizationPreview src="/api/img/visualizations/relationship-view.svg" />
    ),
    truncateTitle: true,
    truncateDescription: true,
    onClick: () => {
      onChange(undefined);
    },
    testId: 'relationship-overview',
  };

  const loadingOption = {
    align: 'center',
    label: 'Loading viewpoints...',
    leftIconName: 'spinner',
  };

  const resolvedOptions = isLoading
    ? [loadingOption]
    : [defaultOption, ...options];

  return (
    <div {...popoverProps}>
      <ButtonWithDropdown
        isDisabled={isDisabled}
        buttonType={ButtonType.SECONDARY}
        dropdownSize={DropdownSize.M}
        alignMenuTo="left"
        options={resolvedOptions}
        {...popoverProps}
      >
        {currentViewpointName || 'Default Overview'}
      </ButtonWithDropdown>
    </div>
  );
}
